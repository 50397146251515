import cn from 'classnames';
import { ThemeStyle } from '@models/PlotConfigs';
import { SVGProps, useEffect, useState } from 'react';
import { PaletteColor } from '@components/PaletteColors';
import { DotSymbol, getD3Symbol } from '@components/plots/PlotTypes';
import { isDefined } from '@util/TypeGuards';
import { symbol as d3Symbol } from 'd3';
import { DATAPOINT_SYMBOL_SIZE } from '@components/plots/PlotUtil';
import { isWhite } from '@/src/util/StringUtil';

export const LegendSymbolSVG = ({
    symbol,
    width = 18,
    height = 18,
    strokeWidth = 1,
    ...props
}: Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'strokeWidth'> & {
    radius?: number;
    width?: number;
    height?: number;
    strokeWidth?: number;
    symbol: DotSymbol;
}) => {
    return (
        <svg width={width} height={height ?? width} {...props}>
            <path
                d={d3Symbol().type(getD3Symbol(symbol)).size(DATAPOINT_SYMBOL_SIZE)() ?? undefined}
                strokeWidth={strokeWidth}
                stroke="currentColor"
                fill="transparent"
                transform={`translate(${width / 2}, ${height / 2})`}
            />
        </svg>
    );
};

export const LegendSVG = ({
    radius,
    width = 18,
    height,
    strokeWidth = 1,
    ...props
}: Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'strokeWidth'> & {
    radius?: number;
    width?: number;
    height?: number;
    strokeWidth?: number;
}) => {
    return (
        <svg width={width} height={height ?? width} {...props}>
            <rect
                x={strokeWidth ?? 0}
                y={strokeWidth ?? 0}
                width={width - strokeWidth * 2}
                height={(height ?? width) - strokeWidth * 2}
                strokeWidth={strokeWidth}
                rx={`${radius ?? 0}`}
                ry={`${radius ?? 0}`}
            />
        </svg>
    );
};

export const LABEL_NO_WRAP_LENGTH = 24;
export const CELL_PADDING_CLASS = 'py-1';
export type LegendItem = {
    color?: string;
    displayName?: string;
    label: string;
    palette_color?: PaletteColor;
    stroke_color?: string;
    symbol?: DotSymbol;
} & ({ palette_color: PaletteColor } | { color: string } | { symbol: DotSymbol });

export const LegendItemView = ({
    item,
    style,
    className,
    labelClassName,
}: { item: LegendItem; className?: string; labelClassName?: string } & Pick<LegendProps, 'style'>) => {
    const label = item.displayName ? item.displayName : item.label;
    const strokeColor = item?.stroke_color ?? item?.color ?? item.palette_color?.color;

    return (
        <div key={label} className={cn('flex items-start', className)}>
            <div key={label}>
                <div className={cn('', CELL_PADDING_CLASS)}>
                    {(item.color || item.palette_color) && (
                        <LegendSVG
                            radius={3}
                            className={cn('mt-0.5 shrink-0')}
                            width={18}
                            height={18}
                            strokeWidth={1}
                            style={{
                                fill: item.color ? item.color : item.palette_color?.color,
                                fillOpacity: style === ThemeStyle.medium ? 0.75 : 1,
                                stroke: isWhite(strokeColor) ? 'rgb(209, 213, 219)' : strokeColor,
                                strokeOpacity: 1,
                            }}
                        />
                    )}
                    {isDefined(item.symbol) && (
                        <LegendSymbolSVG
                            symbol={item.symbol}
                            width={18}
                            height={18}
                            strokeWidth={1}
                            className={cn('mt-0.5 shrink-0 text-gray-500')}
                        />
                    )}
                </div>
            </div>
            <div>
                <div
                    className={cn(CELL_PADDING_CLASS, 'pl-1', labelClassName, 'break-words')}
                    style={{ wordBreak: 'break-word' }}
                >
                    {label}
                </div>
            </div>
        </div>
    );
};

type LegendProps = {
    items: LegendItem[];
    style: ThemeStyle;
    horizontal?: boolean;
    fullWidth?: boolean;
    disableMaxHeight?: boolean;
    disableMaxWidth?: boolean;
    block?: boolean;
};
const PlotLegendView = ({
    items,
    style,
    horizontal = false,
    fullWidth,
    disableMaxHeight = false,
    disableMaxWidth,
    block = false,
}: LegendProps) => {
    const [showPlotlyLegend, setShowPlotlyLegend] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShowPlotlyLegend(true);
        }, 50);
    }, []);

    if (!showPlotlyLegend) {
        return null;
    }
    if (horizontal) {
        return (
            <div className={cn({ 'max-h-16 overflow-y-auto': !disableMaxHeight })}>
                <div
                    className={cn(block ? 'grid' : 'inline-grid', {
                        'lg:max-w-[50vw]': !disableMaxWidth,
                        'grid-cols-2 gap-x-4 gap-y-1 sm:grid-cols-4': fullWidth,
                        'grid-cols-1 gap-x-4 gap-y-1 sm:grid-cols-2': !fullWidth,
                    })}
                >
                    {items
                        .filter((item) => item.palette_color || item.color || isDefined(item.symbol))
                        .map((item, i) => (
                            <LegendItemView item={item} style={style} key={`${item.label}_${i}`} />
                        ))}
                </div>
            </div>
        );
    }
    return (
        <div className="max-h-[600px] flex-shrink-0 overflow-y-auto">
            <div className="grid w-full grid-cols-1 break-all">
                {items
                    .filter((item) => item.palette_color || item.color || isDefined(item.symbol))
                    .map((item, i) => {
                        return <LegendItemView item={item} style={style} key={`${item.label}_${i}`} />;
                    })}
            </div>
        </div>
    );
};

export default PlotLegendView;
