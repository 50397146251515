import { useState } from 'react';
import EditAnnotationForm from '@components/experiments/annotations/EditAnnotationForm';
import Experiment from '@models/Experiment';
import cn from 'classnames';
import AnnotationPlotCardView from './AnnotationPlotCardView';
import { EditIcon } from '../../icons/custom/EditIcon';
import AnnotationsDataTable from './AnnotationsDataTable';
import { useExperimentAnnotationContext } from '@contexts/ExperimentAnnotationContext';
import useOrganizationPermissions from '@/src/hooks/useOrganizationPermissions';
import { ScrollableSidebarContainer } from '../ScrollableSidebarContent';
import PlutoAIPlotView from '../plutoAI/PlutoAIPlotView';
import PlotCommentsView from '../comments/PlotCommentsView';
import CommentsIcon from '../../icons/custom/CommentsIcon';
import { LightBulbIcon } from '@heroicons/react/outline';

export type Props = {
    experiment: Experiment;
};

const AnnotationFormModalView = ({ experiment }: Props) => {
    const [clusterNumber, setClusterNumber] = useState<number>();
    const [formExpanded, setFormExpanded] = useState(true); // change to have open by default based on feedback from team
    const [showPlutoAI, setShowPlutoAI] = useState(false);
    const [commentsExpanded, setCommentsExpanded] = useState(false);
    const toggleFormExpanded = () => setFormExpanded(!formExpanded);
    const toggleCommentsExpanded = () => setCommentsExpanded(!commentsExpanded);
    const { plotData, plotDataError, plotDataLoading, selectedAnnotationSet } = useExperimentAnnotationContext();
    const { features } = useOrganizationPermissions();

    const handleChangeCommentsExpanded = () => {
        if (commentsExpanded && showPlutoAI) return setShowPlutoAI(false);
        setShowPlutoAI(false);
        toggleCommentsExpanded();
    };

    const handleChangeAIExpanded = () => {
        if (commentsExpanded && !showPlutoAI) return setShowPlutoAI(true);
        setShowPlutoAI(true);
        toggleCommentsExpanded();
    };

    const openAISidebar = (arg: number) => {
        setShowPlutoAI(true);
        setCommentsExpanded(true);
        setClusterNumber(arg);
    };

    const handleChangeFormExpanded = () => toggleFormExpanded();

    const commentsEnabled = !!features?.experiment_features.comments_enabled;
    const aiEnabled = !!features?.experiment_features.ai_assistant_enabled;
    if (!selectedAnnotationSet) return <></>;

    return (
        <>
            <EditAnnotationForm
                className={cn('h-full w-[400px] border-r-2 border-r-gray-200 bg-white transition-all duration-700', {
                    '-translate-x-[370px]': !formExpanded,
                    'delay-0': formExpanded,
                })}
                openAISidebar={openAISidebar}
                experiment={experiment}
            />
            <div
                className={cn(
                    'absolute left-[385px] top-1/2 z-20 cursor-pointer rounded-full border-2 border-gray-200 bg-white p-1 transition-all duration-700',
                    {
                        '-translate-x-[370px]': !formExpanded,
                        'delay-0': formExpanded,
                    },
                )}
                onClick={handleChangeFormExpanded}
            >
                <EditIcon height={20} width={20} className="text-indigo-500" />
            </div>
            <div
                className={cn('flex-end absolute h-full !overflow-auto transition-all duration-700', {
                    'w-[calc(100%-30px)] translate-x-[30px]': !formExpanded && !commentsExpanded,
                    'w-[calc(100%-430px)] translate-x-[400px]': formExpanded,
                    'w-[calc(100%-430px)] translate-x-[30px]': commentsExpanded,
                    'w-[calc(100%-630px)] translate-x-[30px]': commentsExpanded && showPlutoAI,
                    'w-[calc(100%-800px)] translate-x-[30px]': formExpanded && !showPlutoAI && commentsExpanded,
                    'w-[calc(100%-990px)] translate-x-[30px]': formExpanded && showPlutoAI && commentsExpanded,
                })}
            >
                <div className="items center flex h-[80%] min-h-96 justify-center p-8">
                    <div className="flex min-h-96 w-full flex-row">
                        <AnnotationPlotCardView />
                    </div>
                </div>
                <div className="mb-8 flex w-full flex-col px-8">
                    <AnnotationsDataTable
                        experiment={experiment}
                        data={plotData}
                        loading={plotDataLoading}
                        error={plotDataError}
                    />
                </div>
            </div>
            {commentsEnabled || aiEnabled ? (
                <>
                    <ScrollableSidebarContainer
                        className={cn(
                            'absolute h-full w-[400px] self-end border-l-2 border-l-gray-200 bg-white transition-all duration-700',
                            {
                                'translate-x-[370px]': !commentsExpanded,
                                'delay-0': commentsExpanded,
                                'w-[600px]': commentsExpanded && showPlutoAI,
                            },
                        )}
                    >
                        {showPlutoAI && !!selectedAnnotationSet ? (
                            <PlutoAIPlotView
                                plot={selectedAnnotationSet}
                                objectType="clusterannotationset"
                                clusterNumber={clusterNumber}
                            />
                        ) : (
                            <PlotCommentsView plot={selectedAnnotationSet} objectType="clusterannotationset" />
                        )}
                    </ScrollableSidebarContainer>
                    {commentsEnabled ? (
                        <div
                            className={cn(
                                'absolute right-[15px] top-[50%] z-20 cursor-pointer rounded-full border-2 border-gray-200 p-1 transition-all duration-700',
                                {
                                    '-translate-x-[370px]': commentsExpanded && !showPlutoAI,
                                    '-translate-x-[570px]': commentsExpanded && !!showPlutoAI,
                                    'delay-0': !commentsExpanded,
                                    'bg-indigo-500': !!showPlutoAI && commentsExpanded,
                                    'bg-slate-100': !showPlutoAI && commentsExpanded && aiEnabled,
                                    'bg-white': !commentsExpanded || !aiEnabled,
                                },
                            )}
                            onClick={handleChangeCommentsExpanded}
                        >
                            <CommentsIcon
                                height={20}
                                width={20}
                                className={cn('text-indigo-500', { 'text-white': !!showPlutoAI && commentsExpanded })}
                            />
                        </div>
                    ) : null}
                    {aiEnabled ? (
                        <div
                            className={cn(
                                'absolute right-[15px] top-[40%] z-20 cursor-pointer rounded-full border-2 border-gray-200 p-1 transition-all duration-700',
                                {
                                    '-translate-x-[370px]': commentsExpanded && !showPlutoAI,
                                    '-translate-x-[570px]': commentsExpanded && !!showPlutoAI,
                                    'delay-0': !commentsExpanded,
                                    'bg-indigo-500': !showPlutoAI && commentsExpanded,
                                    'bg-slate-100': !!showPlutoAI && commentsExpanded && commentsEnabled,
                                    'bg-white': !commentsExpanded || !commentsEnabled,
                                    'top-[50%]': !commentsEnabled,
                                },
                            )}
                            onClick={handleChangeAIExpanded}
                        >
                            <LightBulbIcon
                                height={20}
                                width={20}
                                className={cn('text-indigo-500', { 'text-white': !showPlutoAI && commentsExpanded })}
                            />
                        </div>
                    ) : null}
                </>
            ) : null}
        </>
    );
};

export default AnnotationFormModalView;
