import React from 'react';

import { ChangeEvent, useEffect, useState } from 'react';
import { Comment, CommentObjectType } from '@models/Comment';
import { getFullName, getCuteDate } from '@models/User';
import Experiment from '@models/Experiment';
import { AvatarCircle } from '@components/AvatarCircle';
import { TextareaAutosize } from '@mui/material';
import { EditIcon } from '../../icons/custom/EditIcon';
import { ReplyIcon, TrashIcon } from '@heroicons/react/outline';
import { DisplayTypeIcon } from '../ExperimentIcons';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { capitalizeWordsAndReplaceUnderscores } from '@/src/util/StringUtil';
import PlutoMarkdownContentView from '@components/PlutoMarkdownContentView';

type UpdateCommentParams = { comment_uuid: string; content: string };
type ReplyCommentParams = { parent_comment: Comment; content: string };
type Props = {
    comment: Comment;
    handleDelete: (id: string) => void;
    handleUpdate: (params: UpdateCommentParams) => void;
    handleReply: (params: ReplyCommentParams) => void;
    userId: string;
    commentObjectType?: CommentObjectType;
    replyDisabled?: boolean;
    experiment?: Experiment;
};
const CommentItem = ({
    comment,
    handleDelete,
    handleReply,
    handleUpdate,
    userId,
    commentObjectType,
    replyDisabled,
    experiment,
}: Props) => {
    const [editing, setEditing] = useState<boolean>(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
    const [showReply, setShowReply] = useState<boolean>(false);
    const [replyText, setReplyText] = useState<string>('');
    const [commentText, setCommentText] = useState<string>('');
    const router = useRouter();

    useEffect(() => {
        if (!comment) return;

        setCommentText(comment.content);
    }, [comment]);

    const handleChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setCommentText(e.target.value);
    };

    const handleChangeReplyText = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setReplyText(e.target.value);
    };

    const handleUpdateComment = () => {
        handleUpdate({ comment_uuid: comment.uuid, content: commentText });
        setEditing(false);
        setCommentText('');
    };

    const handleSaveReply = () => {
        handleReply({ parent_comment: comment, content: replyText });
        setShowReply(false);
        setReplyText('');
    };

    const handleHideReply = () => {
        setShowReply(false);
        setReplyText('');
    };

    const handleEditKeyDown = (event) => {
        if (!event.shiftKey && event.key === 'Enter') return handleUpdateComment();
    };

    const handleReplyKeyDown = (event: any) => {
        if (!event.shiftKey && event.key === 'Enter') return handleSaveReply();
    };

    const handleOpenPlotModal = () => {
        const plotLink = generatePlotLink();
        router.replace(plotLink);
    };

    const isMine = comment.created_by.uuid === userId;
    const displayShortname = comment.plot?.display?.display_type || 'sample_scatter_plot';
    const plotName =
        comment.plot?.display?.name ||
        comment.plot?.analysis?.name ||
        capitalizeWordsAndReplaceUnderscores(displayShortname);
    const generatePlotLink = () => {
        const commentsNum = Math.floor(Math.random() * 10000);
        return `/experiments/${experiment?.pluto_id}/analysis?plotId=${comment.plot?.uuid}&openComments=${commentsNum}`;
    };

    return (
        <div className="flex flex-col space-y-1">
            {experiment &&
            commentObjectType === 'experiment' &&
            comment.object_type !== 'experiment' &&
            !replyDisabled ? (
                <div
                    className={cn('flex items-center space-x-2 pl-2 text-primary', {
                        'cursor-pointer hover:opacity-70': comment.object_type === 'plot',
                    })}
                    onClick={comment.object_type === 'plot' ? handleOpenPlotModal : () => null}
                >
                    <DisplayTypeIcon type={displayShortname} height={12} width={12} className="text-inherit" />
                    <p className="text-xs font-semibold tracking-tight text-inherit">{plotName}</p>
                </div>
            ) : null}
            <div
                className={cn('mb-1 flex w-full space-x-2 rounded p-2', {
                    'bg-indigo-50': comment.comment_type === 'note',
                })}
            >
                <AvatarCircle
                    shadow={false}
                    className={'z-10 -ml-2 border first:ml-0'}
                    key={comment.created_by.uuid}
                    imageUrl={comment.created_by.avatar_url}
                    size="xs"
                    userId={comment.created_by.uuid}
                    title={getFullName(comment.created_by)}
                />
                <div className="flex w-full flex-col space-y-1">
                    <div className="flex w-full items-center justify-between">
                        <p className="text-md font-semibold tracking-tight text-[#333333]">
                            {getFullName(comment.created_by)}
                        </p>
                        <p className="text-xs text-[#333333]">{getCuteDate(Date.parse(comment.created_at))}</p>
                    </div>
                    {editing ? (
                        <div>
                            <TextareaAutosize
                                className="no_margin mb-2 w-full rounded border-slate-300"
                                onChange={handleChangeComment}
                                value={commentText}
                                onKeyDown={handleEditKeyDown}
                            />
                        </div>
                    ) : (
                        <p className="break-all tracking-tight">
                            <PlutoMarkdownContentView content={comment.content} />
                        </p>
                    )}
                    <div className="flex w-full items-center justify-between pt-1 pb-8">
                        {!showReply && !replyDisabled && comment.comment_type === 'comment' ? (
                            <div
                                className="flex cursor-pointer items-center space-x-1 text-primary hover:opacity-70"
                                onClick={() => setShowReply(true)}
                            >
                                <ReplyIcon className="text-inherit" height={12} width={12} />
                                <p className="text-xs font-semibold">Reply</p>
                            </div>
                        ) : (
                            <div></div>
                        )}
                        {isMine && !editing && !showConfirmDelete && !showReply ? (
                            <div className="flex items-center">
                                <div
                                    onClick={() => setEditing(true)}
                                    className="mr-1 cursor-pointer font-semibold tracking-tight text-slate-300 hover:text-indigo-600 hover:opacity-70"
                                >
                                    <EditIcon height={14} width={14} />
                                </div>
                                <TrashIcon
                                    height={14}
                                    width={14}
                                    className="cursor-pointer font-semibold tracking-tight text-slate-300 hover:text-error hover:opacity-70"
                                    onClick={() => setShowConfirmDelete(true)}
                                />
                            </div>
                        ) : null}
                        {showReply ? (
                            <div className="flex w-full flex-col space-y-1">
                                <TextareaAutosize
                                    className="no-margin w-full rounded border-slate-300"
                                    onChange={handleChangeReplyText}
                                    value={replyText}
                                    minRows={2}
                                    onKeyDown={handleReplyKeyDown}
                                    placeholder="Reply to comment..."
                                />
                                <div className="flex items-center justify-end space-x-2">
                                    <div
                                        className="cursor-pointer text-xs font-semibold tracking-tight text-primary hover:opacity-70"
                                        onClick={handleSaveReply}
                                    >
                                        <p>Save</p>
                                    </div>
                                    <div
                                        className="cursor-pointer text-xs font-semibold tracking-tight text-error hover:opacity-70"
                                        onClick={handleHideReply}
                                    >
                                        <p>Cancel</p>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {editing ? (
                            <div
                                className="-mt-2 cursor-pointer text-xs font-semibold tracking-tight text-primary hover:opacity-70"
                                onClick={handleUpdateComment}
                            >
                                <p>Save</p>
                            </div>
                        ) : null}
                    </div>
                    {showConfirmDelete ? (
                        <div className="flex justify-end pb-8">
                            <p className="mr-2 text-xs font-semibold tracking-tight">Delete comment?</p>
                            <div
                                className="mr-2 cursor-pointer font-semibold tracking-tight text-error hover:opacity-70"
                                onClick={() => handleDelete(comment.uuid)}
                            >
                                <p className="text-xs">Confirm</p>
                            </div>
                            <div
                                className="cursor-pointer font-semibold tracking-tight text-slate-400 hover:opacity-70"
                                onClick={() => setShowConfirmDelete(false)}
                            >
                                <p className="text-xs">Cancel</p>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="flex flex-col pl-8">
                {comment.children?.map((reply: Comment) => (
                    <CommentItem
                        key={reply.uuid}
                        comment={reply}
                        handleDelete={handleDelete}
                        handleReply={handleReply}
                        handleUpdate={handleUpdate}
                        userId={userId}
                        commentObjectType={commentObjectType}
                        replyDisabled={true}
                    />
                ))}
            </div>
        </div>
    );
};

export default CommentItem;
