import * as Yup from 'yup';
import { ExperimentTypeShortname } from '@models/ExperimentType';
import {
    AnalysisFormValues,
    ClusteringAnalysisFormValues,
    CoverageAnalysisFormValues,
    ExternalAnalysisFormValues,
    FunctionalAnnotationAnalysisFormValues,
    GeneSetEnrichmentAnalysisFormValues,
    ImageAnalysisFormValues,
    SeuratMarkerExpressionAnalysisFormValues,
    OverlapAnalysisFormValues,
    PeakAnalysisFormValues,
    PrincipalComponentsAnalysisFormValues,
    PrismAnalysisFormValues,
    ProteinProteinInteractionAnalysisFormValues,
    SampleCorrelationAnalysisFormValues,
    SeuratOverRepresentationAnalysisFormValues,
    SpreadsheetAnalysisFormValues,
    SurvivalAnalysisFormValues,
    TextAnalysisFormValues,
    TranscriptionFactorEnrichmentAnalysisFormValues,
    TSNEAnalysisFormValues,
    UMAPAnalysisFormValues,
} from '@components/experiments/analyses/AnalysisFormTypes';
import {
    assaySummarySchema,
    clusteringAnalysisSchema,
    coverageAnalysisSchema,
    differentialExpressionAssaySummarySchema,
    differentialExpressionSchema,
    externalAnalysisSchema,
    functionalAnnotationAnalysisSchema,
    geneSetEnrichmentAnalysisSchema,
    imageAnalysisSchema,
    longitudinalSchema,
    seuratMarkerExpressionAnalysisSchema,
    overlapAnalysisSchema,
    peakAnalysisSchema,
    principalComponentsAnalysisSchema,
    prismAnalysisSchema,
    proteinProteinInteractionAnalysisSchema,
    sampleCorrelationAnalysisSchema,
    seuratDifferentialExpressionSchema,
    seuratOverRepresentationAnalysisSchema,
    spreadsheetAnalysisSchema,
    survivalAnalysisSchema,
    textAnalysisSchema,
    transcriptionFactorEnrichmentAnalysisSchema,
    tsneAnalysisSchema,
    umapAnalysisSchema,
} from '@components/experiments/analyses/AnalysisFormSchemas';
import Plot from '@models/Plot';
import Experiment from '@models/Experiment';
import {
    isExternalAnalysis,
    isGeneSetEnrichmentAnalysis,
    isImageAnalysis,
    isPrincipalComponentsAnalysis,
    isPrismAnalysis,
    isProteinProteinInteractionAnalysis,
    isSeuratOverRepresentationAnalysis,
    isSpreadsheetAnalysis,
    isTextAnalysis,
} from '@models/analysis/ExperimentAnalysisHelpers';

import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';
import {
    DifferentialExpressionAnalysis,
    DifferentialExpressionAnalysisFormValues,
} from '@models/analysis/DifferentialExpressionAnalysis';
import {
    SeuratDifferentialExpressionAnalysis,
    SeuratDifferentialExpressionAnalysisFormValues,
} from '@models/analysis/SeuratDifferentialExpressionAnalysis';
import { PeakAnalysis } from '@models/analysis/PeakAnalysis';
import { SurvivalAnalysis } from '@models/analysis/SurvivalAnalysis';
import { AnalysisShortname, isSummaryAnalysisType } from '@models/analysis/AnalysisType';
import { DefaultGeneSetSize } from '@models/analysis/GeneSetEnrichmentAnalysis';
import { UMAPAnalysis } from '@models/analysis/UMAPAnalysis';
import { getMaxPerplexity, TSNEAnalysis } from '@models/analysis/TSNEAnalysis';
import { AnalysisParameters } from '@models/AnalysisParameters';
import { LongitudinalAnalysis } from '@models/analysis/LongitudinalAnalysis';
import {
    DifferentialExpressionContinuousAnalysis,
    DifferentialExpressionContinuousAnalysisFormValues,
} from '@models/analysis/DifferentialExpressionContinuousAnalysis';
import { isDefined } from '@util/TypeGuards';
import { ClusteringAnalysis } from '@models/analysis/ClusteringAnalysis';
import { CoverageAnalysis } from '@models/analysis/CoverageAnalysis';
import { TranscriptionFactorEnrichmentAnalysis } from '@models/analysis/TranscriptionFactorEnrichmentAnalysis';
import { ProteinProteinInteractionAnalysis } from '@models/analysis/ProteinProteinInteractionAnalysis';
import { FunctionalAnnotationAnalysis } from '@models/analysis/FunctionalAnnotationAnalysis';
import { SampleCorrelationAnalysis } from '@models/analysis/SampleCorrelationAnalysis';
import { OverlapAnalysisResponse } from '@/src/models/analysis/OverlapAnalysis';
import { SeuratMarkerExpressionAnalysis } from '@/src/models/analysis/SeuratMarkerExpressionAnalysis';

/**
 * Values needed to initialize the Analysis form
 */
export type FormSetupParams = {
    plot: Plot;
    analysisParameters: AnalysisParameters | null;
    experiment: Experiment;
};

/**
 * Values needed to create a new Experiment Analysis Form
 */
export type FormSetup = { initialValues: AnalysisFormValues; schema: Yup.AnySchema };

/**
 * Get the initial conditions for the analysis form.
 * @param {Plot} plot
 * @param {AssaySummaryAnalysisParameters | DifferentialExpressionAnalysisParameters} analysisParameters
 * @param {Experiment} experiment
 * @return {FormSetup | null} returns null if the experiment type or analysis type is not supported
 */
export const getFormSetup = ({ plot, experiment, analysisParameters }: FormSetupParams): FormSetup | null => {
    const analysis = plot.analysis;

    /** Image analysis  */
    if (plot.analysis_type === 'image' && (!analysis || isImageAnalysis(analysis))) {
        const initialValues: ImageAnalysisFormValues & { analysis_type: AnalysisShortname } = {
            analysis_type: 'image',
            image: analysis?.image_url ?? null,
            methods: analysis?.methods ?? '',
            targets: analysis?.targets ?? [],
        };

        return {
            schema: imageAnalysisSchema,
            initialValues,
        };
    }
    /** Spreadsheet analysis  */
    if (plot.analysis_type === 'spreadsheet' && (!analysis || isSpreadsheetAnalysis(analysis))) {
        const initialValues: SpreadsheetAnalysisFormValues & { analysis_type: AnalysisShortname } = {
            analysis_type: 'spreadsheet',
            spreadsheet: analysis?.spreadsheet_url ?? null,
            methods: analysis?.methods ?? '',
        };

        return {
            schema: spreadsheetAnalysisSchema,
            initialValues,
        };
    }
    /** Prism analysis  */
    if (plot.analysis_type === 'prism' && (!analysis || isPrismAnalysis(analysis))) {
        const initialValues: PrismAnalysisFormValues & { analysis_type: AnalysisShortname } = {
            analysis_type: 'prism',
            experiment_file_id: analysis?.prism_file?.uuid ?? '',
            methods: analysis?.methods ?? '',
        };

        return {
            schema: prismAnalysisSchema,
            initialValues,
        };
    }

    /** External analysis  */
    if (plot.analysis_type === 'external' && (!analysis || isExternalAnalysis(analysis))) {
        const initialValues: ExternalAnalysisFormValues & { analysis_type: AnalysisShortname } = {
            analysis_type: 'external',
            display_file_id: analysis?.display_file?.uuid ?? '',
            results_file_id: analysis?.results_file?.uuid ?? '',
            script_file_id: analysis?.script_file?.uuid ?? '',
            methods: analysis?.methods ?? '',
        };

        return {
            schema: externalAnalysisSchema,
            initialValues,
        };
    }

    /** Text analysis */
    if (plot.analysis_type === 'text' && (!isDefined(analysis) || isTextAnalysis(analysis))) {
        const initialValues: TextAnalysisFormValues & { analysis_type: AnalysisShortname } = {
            analysis_type: 'text',
            content: analysis?.content ?? '',
            targets: analysis?.targets ?? [],
            methods: analysis?.methods ?? '',
        };

        return {
            schema: textAnalysisSchema,
            initialValues,
        };
    }

    // @TODO
    /** Gene set enrichment analysis */
    if (plot?.analysis_type === 'gene_set_enrichment' && (!analysis || isGeneSetEnrichmentAnalysis(analysis))) {
        const initialValues: GeneSetEnrichmentAnalysisFormValues = {
            analysis_type: 'gene_set_enrichment',
            differential_analysis_id: analysis?.differential_analysis?.id ?? '',
            differential_analysis_shortname: analysis?.differential_analysis?.shortname || 'differential_expression',
            gene_set_collections: analysis?.gene_set_collections ?? [],
            min_gene_set_size: analysis?.min_gene_set_size ?? DefaultGeneSetSize.min,
            max_gene_set_size: analysis?.max_gene_set_size ?? DefaultGeneSetSize.max,
            rank_method: analysis?.rank_method ?? 'fold_change',
            plot_metadata: analysis?.plot_metadata,
        };

        return {
            schema: geneSetEnrichmentAnalysisSchema,
            initialValues,
        };
    }

    /** Over-representation analysis */
    if (
        plot?.analysis_type === 'seurat_over_representation' &&
        (!analysis || isSeuratOverRepresentationAnalysis(analysis))
    ) {
        const initialValues: SeuratOverRepresentationAnalysisFormValues = {
            analysis_type: 'seurat_over_representation',
            differential_analysis_id: analysis?.differential_analysis?.uuid ?? '',
            differential_analysis_shortname: analysis?.differential_analysis?.shortname || 'differential_expression',
            gene_set_collections: analysis?.gene_set_collections ?? [],
            n_genes: analysis?.n_genes ?? 200,
            adj_pval_de: analysis?.adj_pval_de ?? 0.05,
        };

        return {
            schema: seuratOverRepresentationAnalysisSchema,
            initialValues,
        };
    }

    /** Principal Components Analysis  */
    if (plot.analysis_type === 'principal_components' && (!analysis || isPrincipalComponentsAnalysis(analysis))) {
        const initialValues: PrincipalComponentsAnalysisFormValues = {
            analysis_type: 'principal_components',
            center_data: analysis?.center_data ?? true,
            scale_data: analysis?.scale_data ?? true,
            variables: analysis?.variables ?? [],
            group_ids: analysis?.groups?.map((g) => g.id) ?? [],
            include_all_samples: !analysis || (analysis.groups?.length === 0 && analysis.variables?.length === 0),
        };
        return {
            schema: principalComponentsAnalysisSchema,
            initialValues,
        };
    }

    if (plot.analysis_type === 'tsne') {
        const sampleCount = analysisParameters?.assay_sample_count ?? 0;
        const max_perplexity = getMaxPerplexity({ sample_count: sampleCount });
        const analysis = plot.analysis as TSNEAnalysis | null;
        const initialValues: TSNEAnalysisFormValues = {
            analysis_type: 'tsne',
            cpm_normalize: analysis?.cpm_normalize ?? true,
            group_ids: analysis?.groups?.map((g) => g.id) ?? [],
            include_all_samples: (analysis?.groups ?? []).length === 0,
            variables: analysis?.variables ?? [],
            perplexity: analysis?.perplexity ?? max_perplexity,
            max_perplexity,
            run_pre_pca: analysis?.run_pre_pca ?? true,
        };

        return {
            schema: tsneAnalysisSchema,
            initialValues,
        };
    }

    if (plot.analysis_type === 'umap') {
        const analysis = plot.analysis as UMAPAnalysis | null;
        const initialValues: UMAPAnalysisFormValues = {
            analysis_type: 'umap',
            cpm_normalize: analysis?.cpm_normalize ?? true,
            group_ids: analysis?.groups?.map((g) => g.id) ?? [],
            include_all_samples: (analysis?.groups ?? []).length === 0,
            n_neighbors: analysis?.n_neighbors ?? 3,
            min_distance: analysis?.min_distance ?? 0.5,
            variables: analysis?.variables ?? [],
            max_sample_count: analysisParameters?.assay_sample_count || 4,
        };

        return {
            schema: umapAnalysisSchema,
            initialValues,
        };
    }

    if (plot.analysis_type === 'longitudinal') {
        const analysis = plot.analysis as LongitudinalAnalysis;
        return {
            schema: longitudinalSchema,
            initialValues: {
                analysis_type: 'longitudinal',
                targets: analysis?.targets ?? [],
                variables: analysis?.variables ?? [],
                target_display_order: analysis?.target_display_order ?? analysis?.targets ?? [],
                group_display_order: analysis?.group_display_order ?? analysis?.groups?.map((g) => g.id) ?? [],
                x_axis_variable_id: analysis?.x_axis_variable_id ?? null,
                group_ids: analysis?.groups?.map((g) => g.id) ?? [],
                split_samples_into_groups: (analysis?.groups?.length ?? 0) > 0,
            },
        };
    }

    if (
        isSummaryAnalysisType(plot.analysis?.analysis_type ?? plot.analysis_type) &&
        experiment.type.shortname !== ExperimentTypeShortname.simple
    ) {
        const analysis = plot.analysis as AssaySummaryAnalysis<string> | null;
        return {
            schema: differentialExpressionAssaySummarySchema,
            initialValues: {
                analysis_type: plot.analysis_type,
                targets: analysis?.targets ?? [],
                variables: analysis?.variables ?? [],
                group_ids: analysis?.groups?.map((g) => g.id) ?? [],
                target_display_order: analysis?.target_display_order ?? analysis?.targets ?? [],
                group_display_order: analysis?.group_display_order ?? analysis?.groups?.map((g) => g.id) ?? [],
            },
        };
    }

    if (isSummaryAnalysisType(plot.analysis?.analysis_type ?? plot.analysis_type)) {
        const analysis = plot.analysis as AssaySummaryAnalysis<number> | null;
        return {
            schema: assaySummarySchema,
            initialValues: {
                analysis_type: plot.analysis_type,
                targets: analysis?.targets ?? [],
                variables: analysis?.variables ?? [],
                group_ids: analysis?.groups?.map((g) => g.id) ?? [],
                target_display_order: analysis?.target_display_order ?? analysis?.targets ?? [],
                group_display_order: analysis?.group_display_order ?? analysis?.groups?.map((g) => g.id) ?? [],
            },
        };
    }

    if (plot.analysis_type === 'seurat_differential_expression') {
        const analysis = plot.analysis as SeuratDifferentialExpressionAnalysis | null;
        const initialValues: SeuratDifferentialExpressionAnalysisFormValues = {
            analysis_type: 'seurat_differential_expression',
            variable_ids: analysis?.variable_ids ?? undefined,
            annotation_set_id: analysis?.annotation_set_id ?? undefined,
            latent_variable_id: analysis?.latent_variable_id ?? undefined,
            group1_id: analysis?.group1?.uuid ?? '',
            group2_id: analysis?.group2?.uuid ?? '',
            log2fc: analysis?.log2fc ?? 0.0,
            stat_test: analysis?.stat_test ?? 'wilcox',
            min_pct: analysis?.min_pct ?? 0.01,
        };
        return {
            schema: seuratDifferentialExpressionSchema,
            initialValues,
        };
    }

    if (plot.analysis_type === 'differential_expression') {
        const analysis = plot.analysis as DifferentialExpressionAnalysis | null;
        const initialValues: DifferentialExpressionAnalysisFormValues = {
            analysis_type: plot.analysis_type,
            variables: analysis?.variables ?? [],
            covariates: analysis?.covariates ?? [],
            control_group_id: analysis?.control_group?.id ?? -1,
            experimental_group_id: analysis?.experimental_group?.id ?? -1,
        };
        return {
            schema: differentialExpressionSchema,
            initialValues,
        };
    }

    if (plot.analysis_type === 'differential_expression_continuous') {
        const analysis = plot.analysis as DifferentialExpressionContinuousAnalysis | null;
        const initialValues: DifferentialExpressionContinuousAnalysisFormValues = {
            analysis_type: plot.analysis_type,
            variables: analysis?.variables ?? [],
            covariates: analysis?.covariates ?? [],
            control_group_id: analysis?.control_group?.id ?? -1,
            experimental_group_id: analysis?.experimental_group?.id ?? -1,
        };
        return {
            schema: differentialExpressionSchema,
            initialValues,
        };
    }

    /*
        Note: Differential Binding is basically a clone of Differential Expression at the moment and uses
        many of the same underlying objects. This may need to be updated this in the future if the two models diverge.
     */
    if (plot.analysis_type === 'differential_binding') {
        const analysis = plot.analysis as DifferentialExpressionAnalysis | null;
        const initialValues: DifferentialExpressionAnalysisFormValues = {
            analysis_type: plot.analysis_type,
            variables: analysis?.variables ?? [],
            covariates: analysis?.covariates ?? [],
            control_group_id: analysis?.control_group?.id ?? -1,
            experimental_group_id: analysis?.experimental_group?.id ?? -1,
        };
        return {
            schema: differentialExpressionSchema,
            initialValues,
        };
    }

    if (plot.analysis_type === 'peak') {
        const analysis = plot.analysis as PeakAnalysis | null;
        const initialValues: PeakAnalysisFormValues = {
            analysis_type: 'peak',
            variables: analysis?.variables ?? [],
            group_ids: analysis?.groups?.map((g) => g.id) ?? [],
            include_all_samples: !analysis || (analysis?.groups?.length === 0 && analysis.variables?.length === 0),
        };
        return {
            schema: peakAnalysisSchema,
            initialValues,
        };
    }

    if (plot.analysis_type === 'survival') {
        const analysis = plot.analysis as SurvivalAnalysis | null;
        const initialValues: SurvivalAnalysisFormValues = {
            analysis_type: 'survival',
            variables: analysis?.variables ?? [],
            status_variable_id: analysis?.status_variable_id ?? null,
            time_to_event_variable_id: analysis?.time_to_event_variable_id ?? null,
            time_to_event_units: analysis?.time_to_event_units ?? '',
            group_ids: analysis?.groups?.map((g) => g.id) ?? [],
        };

        return {
            schema: survivalAnalysisSchema,
            initialValues,
        };
    }

    if (plot.analysis_type === 'clustering') {
        const analysis = plot.analysis as ClusteringAnalysis | null;
        const initialValues: ClusteringAnalysisFormValues = {
            analysis_type: 'clustering',
            variables: analysis?.variables ?? [],
            group_ids: analysis?.groups?.map((g) => g.id) ?? [],
            adj_pval_threshold: analysis?.adj_pval_threshold ?? 0.01,
            log2_fc_threshold: analysis?.log2_fc_threshold ?? 1,
            is_cpm_normalize: analysis?.is_cpm_normalize ?? true,
            is_log2_transform: analysis?.is_log2_transform ?? true,
            is_zscore: analysis?.is_zscore ?? true,
            include_all_samples: !analysis || (analysis.variables?.length ?? 0) === 0,
            differential_analysis_id: analysis?.differential_analysis?.id ?? null,
            differential_analysis_shortname: analysis?.differential_analysis?.shortname ?? null,
            log2_fc_direction: analysis?.log2_fc_direction ?? 'both',
        };
        return {
            schema: clusteringAnalysisSchema,
            initialValues,
        };
    }

    if (plot.analysis_type === 'coverage') {
        const analysis = plot.analysis as CoverageAnalysis | null;
        const initialValues: CoverageAnalysisFormValues = {
            analysis_type: 'coverage',
            variables: analysis?.variables ?? [],
            group_ids: analysis?.groups?.map((g) => g.id) ?? [],
            include_all_samples: !analysis || (analysis.variables?.length ?? 0) === 0,
        };
        return {
            schema: coverageAnalysisSchema,
            initialValues,
        };
    }

    /** Transcription factor enrichment analysis */
    if (plot.analysis_type === 'transcription_factor_enrichment') {
        const analysis = plot.analysis as TranscriptionFactorEnrichmentAnalysis | null;
        const initialValues: TranscriptionFactorEnrichmentAnalysisFormValues = {
            analysis_type: 'transcription_factor_enrichment',
            differential_analysis_id: analysis?.differential_analysis?.id ?? null,
            differential_analysis_shortname: analysis?.differential_analysis?.shortname ?? null,
            tf_target_collection_shortname: analysis?.tf_target_collection?.shortname ?? null,
            adj_pval_threshold: analysis?.adj_pval_threshold ?? 0.01,
            log2_fc_threshold: analysis?.log2_fc_threshold ?? 1,
            log2_fc_direction: analysis?.log2_fc_direction ?? 'both',
            plot_metadata: analysis?.plot_metadata,
        };
        return {
            schema: transcriptionFactorEnrichmentAnalysisSchema,
            initialValues,
        };
    }
    if (plot?.analysis_type === 'functional_annotation') {
        const analysis = plot.analysis as FunctionalAnnotationAnalysis | null;
        const initialValues: FunctionalAnnotationAnalysisFormValues = {
            adj_pval_threshold: analysis?.adj_pval_threshold ?? 0.01,
            analysis_type: 'functional_annotation',
            differential_analysis_id: analysis?.differential_analysis?.id ?? null,
            differential_analysis_shortname: analysis?.differential_analysis?.shortname ?? null,
            fold_change_fill_threshold_lower: analysis?.fold_change_fill_threshold_lower ?? 0.8,
            fold_change_fill_threshold_upper: analysis?.fold_change_fill_threshold_upper ?? 1.2,
            genome_shortname: analysis?.genome_shortname ?? '',
            peak_set_type: analysis?.peak_set_type ?? '',
            sample_ids: analysis?.sample_ids ?? [],
            tss_downstream: analysis?.tss_downstream ?? 3000,
            tss_upstream: analysis?.tss_upstream ?? -3000,
        };

        return {
            schema: functionalAnnotationAnalysisSchema,
            initialValues,
        };
    }

    if (
        plot.analysis_type === 'protein_protein_interaction' &&
        (!analysis || isProteinProteinInteractionAnalysis(analysis))
    ) {
        const analysis = plot.analysis as ProteinProteinInteractionAnalysis | null;
        const initialValues: ProteinProteinInteractionAnalysisFormValues = {
            adj_pval_threshold: analysis?.adj_pval_threshold ?? undefined,
            analysis_type: 'protein_protein_interaction',
            differential_analysis_id: analysis?.differential_analysis_id ?? null,
            differential_analysis_shortname: analysis?.differential_analysis_shortname ?? null,
            fc_direction: analysis?.fc_direction ?? undefined,
            fc_threshold: analysis?.fc_threshold ?? undefined,
            network_type: analysis?.network_type ?? 'functional',
            target_genes_format: analysis?.target_genes_format ?? '',
            targets_biomarker_list: analysis?.targets_biomarker_list ?? undefined,
            targets_csv: undefined,
            targets_csv_filename: analysis?.targets_csv_filename ?? undefined,
            targets: analysis?.targets ?? undefined,
        };
        return {
            schema: proteinProteinInteractionAnalysisSchema,
            initialValues,
        };
    }

    if (plot.analysis_type === 'sample_correlation') {
        const analysis = plot.analysis as SampleCorrelationAnalysis | null;
        const initialValues: SampleCorrelationAnalysisFormValues = {
            analysis_type: 'sample_correlation',
            group_ids: analysis?.groups?.map((g) => g.id) ?? [],
            include_all_samples: !analysis || (analysis.groups?.length === 0 && analysis.variables?.length === 0),
            method_type: analysis?.method_type ?? 'spearman',
            name: analysis?.name ?? 'Sample correlation (all samples)',
            variables: analysis?.variables ?? [],
        };
        return {
            schema: sampleCorrelationAnalysisSchema,
            initialValues,
        };
    }

    if (plot.analysis_type === 'overlap') {
        const analysis = plot.analysis as OverlapAnalysisResponse | null;
        const initialValues: OverlapAnalysisFormValues = {
            analysis_type: 'overlap',
            list_1_id: analysis?.list_1?.uuid ?? null,
            list_2_id: analysis?.list_2?.uuid ?? null,
            list_3_id: analysis?.list_3?.uuid ?? null,
            list_4_id: analysis?.list_4?.uuid ?? null,
            list_5_id: analysis?.list_5?.uuid ?? null,
        };
        return {
            schema: overlapAnalysisSchema,
            initialValues,
        };
    }

    if (plot.analysis_type === 'seurat_marker_expression') {
        const analysis = plot.analysis as SeuratMarkerExpressionAnalysis | null;
        const initialValues: SeuratMarkerExpressionAnalysisFormValues = {
            analysis_type: 'seurat_marker_expression',
            targets: analysis?.targets ?? [],
        };
        return {
            schema: seuratMarkerExpressionAnalysisSchema,
            initialValues,
        };
    }

    return null;
};

export const isImageAnalysisFormFields = (values: AnalysisFormValues): values is ImageAnalysisFormValues => {
    const keys = Object.keys(values);
    return keys.includes('image') && keys.includes('methods');
};

export const isSpreadsheetAnalysisFormFields = (
    values: AnalysisFormValues,
): values is SpreadsheetAnalysisFormValues => {
    const keys = Object.keys(values);
    return keys.includes('spreadsheet') && keys.includes('methods');
};

export const isPrismAnalysisFormFields = (values: AnalysisFormValues): values is PrismAnalysisFormValues => {
    const keys = Object.keys(values);
    return keys.includes('experiment_file_id') && keys.includes('methods');
};

export const isExternalAnalysisFormFields = (values: AnalysisFormValues): values is ExternalAnalysisFormValues => {
    const keys = Object.keys(values);
    return keys.includes('display_file_id') && keys.includes('methods');
};

export const isMultipartProteinProteinAnalysis = (
    values: AnalysisFormValues,
): values is ProteinProteinInteractionAnalysisFormValues => {
    const entries = Object.entries(values);
    return entries.find(([key, value]) => key === 'targets_csv' && value instanceof Blob) !== undefined;
};

export const isOverlapAnalysis = (values: AnalysisFormValues): values is OverlapAnalysisFormValues => {
    const keys = Object.keys(values);
    return keys.includes('list_1_id') && keys.includes('list_2_id');
};
