import { ThemeStyle } from '@models/PlotConfigs';
import React, { useEffect, useRef, useState } from 'react';
import { initialMouseState, MousePosition } from '@components/plots/PlotTypes';
import NoSsr from '@components/NoSsr';
import PlotLegendView from '@components/plots/PlotLegendView';
import Experiment from '@models/Experiment';
import { CircularProgress } from '@mui/material';
import Plot from '@models/Plot';
import { PipelineStatusPlotData, PlotPipelinePlotData } from '@models/ExperimentData';
import MethodsModal from '@components/experiments/methods/MethodsModal';
import ResultsModal from '@components/experiments/ResultsModal';
import Button from '@components/Button';
import { DeleteOutlineRounded } from '@mui/icons-material';
import CanvasPlotShellView from '@components/plots/CanvasPlotShellView';
import PlotDataErrorView from '@components/plots/PlotDataErrorView';
import cn from 'classnames';
import { ApiError } from '@services/ApiError';
import LocalStorageService from '@util/LocalStorageService';
import usePlot from '@hooks/usePlot';
import usePlotLegendItems from '@hooks/usePlotLegendItems';
import { isDefined } from '@util/TypeGuards';
import {
    getPlotDisplayTitle,
    isDoubleClickEnabled,
    isDoubleWidePlot,
    isRightClickEnabled,
} from '@components/plots/PlotUtil';
import PlutoErrorBoundary from '@components/PlutoErrorBoundary';
import { PlotContextProvider } from '@contexts/PlotContext';
import { ExperimentAnalysisWithCustomLegend, isPipelineAnalysis } from '@models/analysis/ExperimentAnalysis';
import AnalysisCategoryPlotContentView from '@components/analysisCategories/AnalysisCategoryPlotContentView';
import { isBlank } from '@util/StringUtil';
import useDebouncedResizeObserver from '@hooks/useDebouncedResizeObserver';
import useAnalysisParameters from '@hooks/useAnalysisParameters';
import PlotCardMenuButton from '@components/plots/PlotCardMenuButton';
import { ResizableContainerContextProvider } from '@contexts/ResizableContainerContext';
import { PlotOverrideSummary, useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import { DragMode } from '../analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';
import CustomPlotLegendView from './CustomPlotLegendView';
import { AnalysisShortname } from '@/src/models/analysis/AnalysisType';
import DotPlotDisplayOption from '@/src/models/plotDisplayOption/DotPlotDisplayOption';
import WhiteIconButton from '../WhiteIconButton';
import { ArrowsExpandIcon } from '@heroicons/react/outline';
import useOrganizationPermissions from '@/src/hooks/useOrganizationPermissions';
import useExperimentPermissions from '@/src/hooks/useExperimentPermissions';
import { validateAnalysisInputsPlotDataReady } from '../experiments/analyses/inputs/AnalysisInputFormUtil';
import PlotTitle from './PlotTitle';

const MIN_LEGEND_COLUMN_WIDTH = 525;
export type LegendPosition = 'bottom' | 'right' | 'auto';
export type Props = {
    aspectRatio?: number | null;
    cursorClassName?: string;
    disableDownloadLinks?: boolean;
    disableFullWidth?: boolean;
    disableMaxHeight?: boolean;
    disableRoundedCorners?: boolean;
    disableSelected?: boolean;
    dragMode?: DragMode;
    experiment: Experiment;
    extraPadding?: boolean;
    hideEditButton?: boolean;
    hideLegend?: boolean;
    hideMoreMenu?: boolean;
    hidePlotShellButtons?: boolean;
    hideBorders?: boolean;
    hideTitle?: boolean;
    isDragging?: boolean;
    isExportMode?: boolean;
    legendPosition?: LegendPosition;
    minHeight?: number;
    noPadding?: boolean;
    overrides?: PlotOverrideSummary | null;
    plot: Plot;
    publicationMode?: boolean;
    publicKey?: string | null;
    resizeNoLeading?: boolean;
    resizeNoTrailing?: boolean;
    showExpand?: boolean;
    showExperimentLink?: boolean;
    showHeader?: boolean;
};

const PlotCardView = ({
    cursorClassName,
    disableDownloadLinks,
    disableFullWidth = false,
    disableMaxHeight = false,
    disableRoundedCorners = false,
    disableSelected = false,
    dragMode = undefined,
    experiment,
    hideEditButton,
    hideLegend = false,
    hideMoreMenu = false,
    hidePlotShellButtons = false,
    hideBorders = false,
    hideTitle = false,
    isExportMode = false,
    legendPosition = 'auto',
    overrides: overridesParam,
    plot: plotParam,
    publicationMode,
    publicKey,
    showExpand = false,
    showExperimentLink,
    showHeader = true,
}: Props) => {
    const plotId = plotParam.uuid;
    const [methodsOpen, setMethodsOpen] = React.useState(false);
    const [resultsOpen, setResultsOpen] = React.useState(false);
    const plotExportRef = useRef<HTMLDivElement>(null);
    const [mousePosition, setMousePosition] = useState<MousePosition>(initialMouseState());
    const [showLegendBottom, setShowLegendBottom] = useState(legendPosition === 'bottom');
    const permissions = useExperimentPermissions(experiment, !!publicKey);
    const { features } = useOrganizationPermissions();

    const plotContainerRef = useRef<HTMLDivElement>(null);
    const { ref: plotSizeRef, size } = useDebouncedResizeObserver({ wait: 200, useDebounce: true });
    const { size: plotContainerSize } = useDebouncedResizeObserver({ ref: plotContainerRef, useDebounce: true });
    const { plot, plotData, plotDataError, plotDataLoading, isPlotDataValidating, mutatePlot } = usePlot({
        experiment,
        plot: plotParam,
        plotId,
        overrides: overridesParam,
        publicKey,
    });
    const { analysisParameters } = useAnalysisParameters({ plot, experiment, publicKey });
    const {
        style: legendStyle,
        legendItems,
        horizontalOnly,
    } = usePlotLegendItems({ plot, plotData, experiment, publicKey });

    const {
        clearPlotOverrides,
        getPlotOverrides,
        handleChangeSelectedPlot,
        selectedPlot,
        setCurrentContextMenuPlotId,
        setCurrentPlotPanel,
        setExperimentModalOpen,
        setOpenOnComments,
        setOpenOnPlot,
        setZoomTransform,
    } = useExperimentDetailViewContext();

    const overrides: PlotOverrideSummary = getPlotOverrides?.(plotId) ?? { hasOverrides: false };
    const isSelected = !disableSelected && plotId === selectedPlot?.uuid;
    const loading = !!plot?.analysis && plotDataLoading;
    const hasValidAnalysisInputs =
        plot?.analysis_form_type === 'analysis_input' ? validateAnalysisInputsPlotDataReady(plot.analysis) : true;
    const dataReady = isDefined(plot?.analysis?.uuid) && !plotDataLoading && hasValidAnalysisInputs;
    const display = plot?.display as DotPlotDisplayOption;

    const pipelineProcessing = (plotData as PipelineStatusPlotData)?.pipeline_status === 'in_progress';
    const hasPipelinePlotData =
        ((plotData as PipelineStatusPlotData)?.items ?? []).length > 0 ||
        (plotData?.count ?? 0) > 0 ||
        Boolean((plotData as PlotPipelinePlotData)?.plots?.images);

    const showPlotTitle =
        !hideTitle && !pipelineProcessing && (!isPipelineAnalysis(plot?.analysis) || hasPipelinePlotData);
    const plotDisplayName = getPlotDisplayTitle(plot);
    const showLegend = !hideLegend && !pipelineProcessing;

    useEffect(() => {
        if (legendPosition === 'bottom') {
            setShowLegendBottom(true);
        } else if (size.width && size.width < MIN_LEGEND_COLUMN_WIDTH && legendPosition !== 'right') {
            setShowLegendBottom(true);
        } else if (display.hasOwnProperty('is_transposed') && !display?.is_transposed && legendPosition !== 'right') {
            setShowLegendBottom(true);
        } else {
            setShowLegendBottom(false);
        }
    }, [legendPosition, size.width, plot?.display]);

    const handleRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (LocalStorageService.isDevMode()) {
            return;
        }
        event.preventDefault();
        setMousePosition({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const isDoubleWide = isDoubleWidePlot(plot);

    const expandPlotFullScreen = () => {
        handleChangeSelectedPlot?.(plot);
        if (features?.experiment_features.comments_enabled && permissions.canEdit) {
            // Open comments if permissions allow
            setOpenOnComments?.(true);
        } else {
            // Else open plot fullscreen
            setOpenOnPlot?.(true);
        }
        setCurrentPlotPanel?.(plot?.analysis?.uuid ? 'plot' : 'analysis');
        setExperimentModalOpen?.(true);
    };

    if (!!plotDataError)
        return (
            <div className="flex flex-col justify-center px-8 py-16 text-center">
                <PlotDataErrorView
                    error={ApiError.getMessage(plotDataError)}
                    code={plotDataError?.code}
                    plot={plot ?? plotParam}
                />
            </div>
        );
    if (!dataReady)
        return (
            <div className="relative flex h-full w-full items-center rounded-2xl bg-white" data-cy="plot-card">
                <CanvasPlotShellView plot={plot} loading={loading} experiment={experiment} />
            </div>
        );

    return (
        <NoSsr>
            <PlotContextProvider
                experiment={experiment}
                analysisParameters={analysisParameters}
                plot={plot ?? plotParam}
                plotId={plotId}
                hideEditButton={hideEditButton}
                plotData={plotData}
                plotDataError={plotDataError}
                plotDataLoading={plotDataLoading}
                isPlotDataValidating={isPlotDataValidating}
                overrides={overrides}
                publicationMode={publicationMode}
                isExportMode={isExportMode}
                disableMaxHeight={disableMaxHeight}
                mutatePlot={mutatePlot}
                isEditing={isSelected}
                setDragEnabled={() => false}
            >
                <div
                    className={cn('group relative h-full w-full bg-white', cursorClassName, {
                        'md:col-span-2': isDoubleWide && !publicationMode,
                        'cursor-pointer': isBlank(cursorClassName) && isDoubleClickEnabled(plot),
                        'rounded-2xl': !disableRoundedCorners,
                        'overflow-hidden': plot?.analysis_type === 'image',
                    })}
                    data-cy="plot-card"
                    data-plot-id={plot?.uuid}
                    onContextMenu={isRightClickEnabled(plot) ? handleRightClick : undefined}
                >
                    {isPlotDataValidating && !loading && (
                        <div className={'absolute left-4 top-4 flex items-center space-x-1'}>
                            <CircularProgress size={12} />
                            <span className="text-xs text-default"></span>
                        </div>
                    )}
                    <div ref={plotSizeRef} className="h-full">
                        <div className="relative flex h-full flex-grow flex-col-reverse">
                            {showExpand ? (
                                <span className="absolute right-2 top-9 z-50 hidden opacity-0 transition-all duration-200 ease-in-out group-hover:inline-block group-hover:opacity-100">
                                    <WhiteIconButton onClick={expandPlotFullScreen} color="primary">
                                        <ArrowsExpandIcon width={24} />
                                    </WhiteIconButton>
                                </span>
                            ) : null}
                            <div
                                className={cn('flex h-full flex-col space-y-2 ', {
                                    'justify-between':
                                        dataReady &&
                                        plot?.display?.display_type !== 'image' &&
                                        !pipelineProcessing &&
                                        showPlotTitle,
                                    'justify-center': !dataReady || pipelineProcessing || !showPlotTitle,
                                    'pb-4': overrides.hasOverrides,
                                })}
                            >
                                {dataReady && (
                                    <div ref={plotExportRef} className="flex h-full max-h-full w-full flex-col">
                                        <div className="flex w-full flex-col">
                                            {showHeader ? (
                                                <div
                                                    className={cn(
                                                        'flex w-full flex-row justify-between rounded-tl rounded-tr px-4 py-1',
                                                        {
                                                            'border-b border-slate-400': !hideBorders,
                                                        },
                                                    )}
                                                >
                                                    <p className="text-lg font-semibold tracking-tight">
                                                        {plotDisplayName}
                                                    </p>

                                                    <div className="flex flex-row space-x-2">
                                                        {!hideMoreMenu && (
                                                            <PlotCardMenuButton
                                                                plot={plot ?? plotParam}
                                                                plotData={plotData}
                                                                experiment={experiment}
                                                                setMethodsOpen={setMethodsOpen}
                                                                setResultsOpen={setResultsOpen}
                                                                ready={dataReady}
                                                                mousePosition={mousePosition}
                                                                setMousePosition={setMousePosition}
                                                                hideEditButton={hideEditButton}
                                                                showExperimentLink={showExperimentLink}
                                                                disableDownloadLinks={disableDownloadLinks}
                                                                onMenuOpen={() => {
                                                                    setCurrentContextMenuPlotId?.(plot?.uuid ?? null);
                                                                }}
                                                                onMenuClose={() => {
                                                                    setCurrentContextMenuPlotId?.(null);
                                                                }}
                                                                publicKey={publicKey}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="flex w-full flex-row justify-center px-4 py-1">
                                                    <PlotTitle plot={plot} publicationMode={publicationMode} />
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className={cn(
                                                'flex h-full w-full justify-center overflow-hidden',

                                                {
                                                    'flex-col': showLegendBottom || horizontalOnly,
                                                    'py-2 px-4': showHeader,
                                                },
                                            )}
                                        >
                                            <div
                                                className="relative flex h-full w-full justify-center overflow-hidden"
                                                ref={plotContainerRef}
                                            >
                                                <ResizableContainerContextProvider
                                                    containerRef={plotContainerRef}
                                                    size={plotContainerSize}
                                                >
                                                    <PlutoErrorBoundary>
                                                        <AnalysisCategoryPlotContentView
                                                            dragMode={dragMode}
                                                            setZoomTransform={setZoomTransform}
                                                        />
                                                    </PlutoErrorBoundary>
                                                </ResizableContainerContextProvider>
                                            </div>

                                            {legendItems.length > 0 && showLegend && (
                                                <div
                                                    className={cn('mt-4', {
                                                        'max-w-[30%] flex-shrink-0':
                                                            !showLegendBottom && !horizontalOnly,
                                                    })}
                                                >
                                                    <PlotLegendView
                                                        items={legendItems}
                                                        style={legendStyle ?? ThemeStyle.outline}
                                                        horizontal={showLegendBottom || horizontalOnly}
                                                        fullWidth={
                                                            !disableFullWidth &&
                                                            (plot?.display?.is_full_width || publicationMode)
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {showLegend &&
                                                (plot?.analysis as ExperimentAnalysisWithCustomLegend)
                                                    ?.pipeline_status === 'completed' && (
                                                    <div
                                                        className={cn('mt-4', {
                                                            'max-w-[30%] flex-shrink-0':
                                                                !showLegendBottom && !horizontalOnly,
                                                        })}
                                                    >
                                                        <CustomPlotLegendView
                                                            plot={plot}
                                                            shortname={
                                                                plot.analysis?.analysis_type as AnalysisShortname
                                                            }
                                                            plotData={plotData as PipelineStatusPlotData}
                                                            showLegendBottom={showLegendBottom}
                                                            publicationMode={publicationMode}
                                                            isExportMode={isExportMode}
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                )}
                                {!dataReady && (
                                    <div className="h-full w-full">
                                        <CanvasPlotShellView
                                            plot={plot}
                                            loading={loading}
                                            experiment={experiment}
                                            hideButtons={hidePlotShellButtons}
                                        />
                                    </div>
                                )}
                            </div>
                            {dataReady && overrides.hasOverrides && (
                                <div className="absolute bottom-0 left-0 flex-shrink-0 px-2 py-2 text-red-800">
                                    <Button
                                        color="inherit"
                                        startIcon={<DeleteOutlineRounded />}
                                        variant="text"
                                        onClick={() => clearPlotOverrides?.(plotId)}
                                        size="small"
                                    >
                                        Discard Changes
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {plot?.analysis && (
                    <>
                        <MethodsModal
                            open={methodsOpen}
                            onClose={() => setMethodsOpen(false)}
                            plot={plot}
                            experiment={experiment}
                            overrideAnalysisId={overrides.analysis_id}
                            publicKey={publicKey}
                        />
                        <ResultsModal
                            open={resultsOpen}
                            onClose={() => setResultsOpen(false)}
                            plot={plot}
                            experiment={experiment}
                        />
                    </>
                )}
            </PlotContextProvider>
        </NoSsr>
    );
};

export default PlotCardView;
