import { AnalysisShortname } from '@/src/models/analysis/AnalysisType';
import DotPlotLegend from '../analysisCategories/comparative/plots/dot/DotPlotLegend';
import Plot from '@/src/models/Plot';
import { PipelineStatusPlotData } from '@/src/models/ExperimentData';

type Props = {
    isExportMode?: boolean;
    plot: Plot;
    plotData: PipelineStatusPlotData;
    publicationMode?: boolean;
    shortname: AnalysisShortname;
    showLegendBottom?: boolean;
};
const CustomPlotLegendView = ({
    isExportMode,
    plot,
    plotData,
    publicationMode,
    shortname,
    showLegendBottom,
}: Props) => {
    switch (shortname) {
        case 'seurat_over_representation':
        case 'sample_correlation':
            return (
                <DotPlotLegend
                    isExportMode={isExportMode}
                    plot={plot}
                    plotData={plotData}
                    publicationMode={publicationMode}
                    showLegendBottom={showLegendBottom}
                />
            );
        case 'seurat_marker_expression':
            if (plot?.display?.display_type === 'cell_scatter_plot') {
                return (
                    <DotPlotLegend
                        plot={plot}
                        plotData={plotData}
                        showLegendBottom={showLegendBottom}
                        publicationMode={publicationMode}
                        valueKey="value"
                        colorTitle={`Log-normalized
expression`}
                    />
                );
            }
            break;
        default:
            return null;
    }
};

export default CustomPlotLegendView;
