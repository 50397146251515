import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import Plot from '@models/Plot';
import { ScrollableSidebarContent, ScrollableSidebarFooter } from '@components/experiments/ScrollableSidebarContent';
import Experiment from '@models/Experiment';
import PlotDisplayFormSubmitButton from '@components/experiments/plotDisplay/PlotDisplayFormSubmitButton';
import usePlotDisplayForm from '@hooks/usePlotDisplayForm';
import { PlotDisplayShortname } from '@models/PlotDisplayType';
import VolcanoDisplayFields from '@components/experiments/plotDisplay/forms/VolcanoDisplayFields';
import BoxPlotFields from '@components/experiments/plotDisplay/forms/BoxPlotFields';
import BarPlotFields from '@components/experiments/plotDisplay/forms/BarPlotFields';
import HeatmapFields from '@components/experiments/plotDisplay/forms/HeatmapFields';
import SelectableItem from '@components/forms/SelectableItem';
import cn from 'classnames';
import { DisplayTypeIcon } from '@components/experiments/ExperimentIcons';
import { Tooltip } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import ImagePlotDisplayFields from '@components/experiments/plotDisplay/forms/ImagePlotDisplayFields';
import SpreadsheetPlotDisplayFields from '@components/experiments/plotDisplay/forms/spreadsheetPlotDisplayFields';
import PrismGraphsetDisplayFields from '@components/experiments/plotDisplay/forms/PrismGraphsetDisplayFields';
import { getPlotDisplayFormSetup } from '@components/experiments/plotDisplay/PlotDisplayFormUtil';
import SampleScatterPlotDisplayFields from '@components/experiments/plotDisplay/forms/SampleScatterPlotDisplayFields';
import useAnalysisParameters from '@hooks/useAnalysisParameters';
import EnrichmentPlotDisplayFields from '@components/experiments/plotDisplay/forms/EnrichmentPlotDisplayFields';
import useExperimentSettings from '@hooks/useExperimentSettings';
import IGVPlotDisplayFields from '@components/experiments/plotDisplay/forms/IGVPlotDisplayFields';
import KaplanMeierCurvePlotDisplayFields from '@components/experiments/plotDisplay/forms/KaplanMeierCurvePlotDisplayFields';
import ScoreBarPlotDisplayFields from '@components/experiments/plotDisplay/forms/ScoreBarPlotDisplayFields';
import { isDisplayTypeAvailableForAnalysisVersion } from '@components/plots/util/PipelineVersionUtil';
import { PipelineStatusAnalysis } from '@models/analysis/ExperimentAnalysis';
import { blankToUndefined, isBlank } from '@util/StringUtil';
import { deepEqual, getChangedValues } from '@util/ObjectUtil';
import AggregateFormErrorAlert from '@components/experiments/AggregateFormErrorAlert';
import LinePlotDisplayFields from '@components/experiments/plotDisplay/forms/LinePlotDisplayFields';
import TextDisplayFields from '@components/experiments/plotDisplay/forms/TextDisplayFields';
import ImageHeatmapDisplayFields from '@components/experiments/plotDisplay/forms/ImageHeatmapDisplayFields';
import TornadoPlotDisplayFields from '@components/experiments/plotDisplay/forms/TornadoPlotDisplayFields';
import ProfilePlotDisplayFields from '@components/experiments/plotDisplay/forms/ProfilePlotDisplayFields';
import ExternalPlotDisplayFields from '@components/experiments/plotDisplay/forms/ExternalPlotDIsplayFields';
import FormikListener from '@components/forms/FormikListener';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import DotPlotFields from './forms/dotPlot/DotPlotFields';
import NetworkGraphDisplayFields from './forms/NetworkGraphDisplayFields';
import PieChartDisplayFields from './forms/PieChartDisplayFields';
import StackedBarPlotDisplayFields from './forms/StackedBarPlotDisplayFields';
import VennDiagramDisplayFields from './forms/VennDiagramDisplayFields';
import ViolinPlotDisplayFields from './forms/ViolinPlotDisplayFields';
import RidgePlotDisplayFields from './forms/RidgePlotDisplayFields';
import CellScatterPlotDisplayFields from './forms/CellScatterPlotDisplayFields';

type Props = { plot: Plot; experiment: Experiment };
const PlotDisplayForm = ({ plot, experiment }: Props) => {
    const { experimentTypes } = useExperimentSettings(experiment);
    const {
        displaySaveDisabled,
        handleFormSubmit,
        handleSaveCurrentView,
        savingCurrentView,
        setDisplaySaveDisabled,
        setUpdateGroupsDisabled,
        showSaveSuccess,
        updateGroupsDisabled,
    } = usePlotDisplayForm({
        plot,
        experiment,
    });
    const { setCurrentChanges, currentChanges } = useExperimentDetailViewContext();
    const experimentType = experimentTypes.find((type) => type.shortname === experiment?.type.shortname);
    const analysisType = experimentType?.analysis_types?.find((type) => type.shortname === plot.analysis_type);
    const displayTypes = analysisType?.display_types ?? [];

    const [chartType, setChartType] = useState<PlotDisplayShortname | null>(plot.display?.display_type ?? null);

    const { analysisParameters } = useAnalysisParameters({
        plot,
        experiment,
    });
    const showPlotTypePicker = displayTypes.length > 1;
    const setup = getPlotDisplayFormSetup({
        display: plot.display,
        analysis: plot.analysis,
        displayType: chartType,
    });
    const pipelineVersion = (plot.analysis as PipelineStatusAnalysis | null)?.pipeline_version;

    const getFormFields = (chartType: PlotDisplayShortname) => {
        switch (chartType) {
            case 'volcano_plot':
            case 'volcano_plot_v2':
                return <VolcanoDisplayFields plot={plot} experiment={experiment} key={chartType} />;
            case 'box_plot':
                return <BoxPlotFields plot={plot} experiment={experiment} />;
            case 'bar_plot':
                return <BarPlotFields plot={plot} experiment={experiment} />;
            case 'dot_plot':
                return <DotPlotFields plot={plot} />;
            case 'heatmap':
                return <HeatmapFields plot={plot} experiment={experiment} />;
            case 'image':
                return <ImagePlotDisplayFields plot={plot} experiment={experiment} />;
            case 'spreadsheet':
                return <SpreadsheetPlotDisplayFields plot={plot} experiment={experiment} />;
            case 'prism_graphset':
                return <PrismGraphsetDisplayFields plot={plot} experiment={experiment} />;
            case 'sample_scatter_plot':
                return (
                    <SampleScatterPlotDisplayFields
                        plot={plot}
                        experiment={experiment}
                        analysisParameters={analysisParameters}
                    />
                );
            case 'enrichment_plot':
                return (
                    <EnrichmentPlotDisplayFields
                        plot={plot}
                        experiment={experiment}
                        analysisParameters={analysisParameters}
                    />
                );
            case 'score_bar_plot':
                return (
                    <ScoreBarPlotDisplayFields
                        plot={plot}
                        experiment={experiment}
                        analysisParameters={analysisParameters}
                    />
                );
            case 'line_plot':
                return (
                    <LinePlotDisplayFields
                        plot={plot}
                        experiment={experiment}
                        analysisParameters={analysisParameters}
                    />
                );
            case 'igv_plot':
                return (
                    <IGVPlotDisplayFields plot={plot} experiment={experiment} analysisParameters={analysisParameters} />
                );
            case 'kaplan_meier_curve':
                return (
                    <KaplanMeierCurvePlotDisplayFields
                        plot={plot}
                        experiment={experiment}
                        analysisParameters={analysisParameters}
                    />
                );
            case 'text':
                return <TextDisplayFields />;
            case 'image_heatmap':
                return (
                    <ImageHeatmapDisplayFields
                        plot={plot}
                        experiment={experiment}
                        analysisParameters={analysisParameters}
                    />
                );
            case 'tornado_plot':
                return <TornadoPlotDisplayFields plot={plot} />;
            case 'profile_plot':
                return <ProfilePlotDisplayFields plot={plot} />;
            case 'external':
                return <ExternalPlotDisplayFields />;
            case 'network_graph':
                return (
                    <NetworkGraphDisplayFields
                        plot={plot}
                        handleSaveCurrentView={handleSaveCurrentView}
                        savingCurrentView={savingCurrentView}
                        showSaveSuccess={showSaveSuccess}
                    />
                );
            case 'pie_chart':
                return <PieChartDisplayFields plot={plot} experiment={experiment} />;
            case 'stacked_bar_plot':
                return <StackedBarPlotDisplayFields plot={plot} />;
            case 'venn_diagram':
                return <VennDiagramDisplayFields plot={plot} />;
            case 'violin_plot':
                return (
                    <ViolinPlotDisplayFields
                        analysisParameters={analysisParameters}
                        experiment={experiment}
                        plot={plot}
                        setDisplaySaveDisabled={setDisplaySaveDisabled}
                        setUpdateGroupsDisabled={setUpdateGroupsDisabled}
                        updateGroupsDisabled={updateGroupsDisabled}
                    />
                );
            case 'ridge_plot':
                return (
                    <RidgePlotDisplayFields
                        analysisParameters={analysisParameters}
                        experiment={experiment}
                        plot={plot}
                        setDisplaySaveDisabled={setDisplaySaveDisabled}
                        setUpdateGroupsDisabled={setUpdateGroupsDisabled}
                        updateGroupsDisabled={updateGroupsDisabled}
                    />
                );
            case 'cell_scatter_plot':
                return (
                    <CellScatterPlotDisplayFields
                        analysisParameters={analysisParameters}
                        experiment={experiment}
                        plot={plot}
                        setDisplaySaveDisabled={setDisplaySaveDisabled}
                        setUpdateGroupsDisabled={setUpdateGroupsDisabled}
                        updateGroupsDisabled={updateGroupsDisabled}
                    />
                );
            default:
                return <div>This plot type is not yet supported {chartType}</div>;
        }
    };

    const handleOnChange = useCallback(
        (values) => {
            const isEqual = deepEqual(values, setup?.initialValues);
            if (!isEqual) {
                const changedValues = getChangedValues(values, setup?.initialValues);
                if (changedValues && !deepEqual(changedValues, currentChanges)) {
                    setCurrentChanges(changedValues);
                }
            } else if (currentChanges) {
                setTimeout(() => {
                    setCurrentChanges(null);
                }, 300);
            }
        },
        [currentChanges, setup?.initialValues],
    );

    return (
        <Formik
            initialValues={setup?.initialValues ?? {}}
            validationSchema={setup?.schema}
            onSubmit={handleFormSubmit}
            enableReinitialize
            key={chartType}
        >
            {({ status, errors, touched, values }) => {
                const errorMessages = Object.keys(errors)
                    .filter((name) => touched[name] && !isBlank(errors[name]))
                    .map((name) => errors[name]);
                return (
                    <>
                        <ScrollableSidebarContent className="h-full px-8">
                            <div className="h-full w-full">
                                {showPlotTypePicker && (
                                    <div className="form-field">
                                        <span className="field-label block">Plot type</span>
                                        <div className="flex flex-row" data-cy="display-type-picker">
                                            {displayTypes.map((type, index) => {
                                                const versionEnabled = isDisplayTypeAvailableForAnalysisVersion({
                                                    version: pipelineVersion,
                                                    display_type: type.shortname,
                                                    analysis_type: plot.analysis_type,
                                                });

                                                const isDisabled = !type.is_enabled || !versionEnabled;

                                                return (
                                                    <SelectableItem
                                                        key={`type_${index}`}
                                                        selected={chartType === type.shortname}
                                                        disabled={isDisabled}
                                                        onSelect={() => setChartType(type.shortname)}
                                                        className={cn(
                                                            'relative mr-2 flex flex-col items-center space-y-2 px-4 text-center',
                                                        )}
                                                        cyId={type.shortname}
                                                    >
                                                        <div
                                                            className={cn('flex flex-grow items-center', {
                                                                'px-5 text-gray-300': isDisabled,
                                                            })}
                                                        >
                                                            <DisplayTypeIcon
                                                                type={type.shortname}
                                                                className={blankToUndefined(
                                                                    cn({
                                                                        'text-gray-400': isDisabled,
                                                                    }),
                                                                )}
                                                            />
                                                        </div>
                                                        <span
                                                            className={cn({
                                                                'text-dark': !isDisabled,
                                                                'text-gray-500': isDisabled,
                                                            })}
                                                        >
                                                            {type.display_name}{' '}
                                                            {isDisabled && (
                                                                <Tooltip
                                                                    title={
                                                                        <span className="text-sm">
                                                                            {!versionEnabled
                                                                                ? 'This plot type requires the analysis to be upgraded to the latest version'
                                                                                : 'Coming soon!'}
                                                                        </span>
                                                                    }
                                                                    arrow
                                                                    placement="top"
                                                                    disableFocusListener
                                                                >
                                                                    <InfoOutlined
                                                                        className="absolute right-1.5 top-1.5 text-indigo-600"
                                                                        fontSize="small"
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                        </span>
                                                    </SelectableItem>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                                {chartType && getFormFields(chartType)}
                            </div>
                        </ScrollableSidebarContent>
                        <ScrollableSidebarFooter>
                            {status && status.error && errorMessages.length === 0 && (
                                <p className="mb-4 break-words rounded-lg bg-error px-2 py-2 text-error">
                                    {status.error}
                                </p>
                            )}
                            <AggregateFormErrorAlert />
                            <PlotDisplayFormSubmitButton
                                text="Save changes"
                                disabled={savingCurrentView || displaySaveDisabled}
                            />
                        </ScrollableSidebarFooter>
                        <FormikListener values={values} callback={handleOnChange} />
                    </>
                );
            }}
        </Formik>
    );
};

export default PlotDisplayForm;
