import ChartTitleField from '@components/experiments/plotDisplay/fields/ChartTitleField';
import PValueToggleField from '@components/experiments/plotDisplay/fields/PValueToggleField';
import PValueField from '@components/experiments/plotDisplay/fields/PValueField';
import FoldChangeToggleField from '@components/experiments/plotDisplay/fields/FoldChangeToggleField';
import FoldChangeFieldGroup from '@components/experiments/plotDisplay/groups/FoldChangeFieldGroup';
import ThemeFieldGroup from '@components/experiments/plotDisplay/groups/ThemeFieldGroup';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Plot from '@models/Plot';
import FullWidthToggleField from '@components/experiments/plotDisplay/fields/FullWidthToggleField';
import AsyncTargetPicker from '@components/AsyncTargetPicker';
import Experiment from '@models/Experiment';
import VolcanoPlotDisplayOption, {
    DefaultVolcanoPlotDisplayOption,
} from '@models/plotDisplayOption/VolcanoPlotDisplayOption';
import { CustomLegendColorItem } from '@/src/components/experiments/plotDisplay/groups/ControlledCustomLegendColorField';
import { useFormikContext } from 'formik';
import { DisplayFormValue } from '@models/PlotDisplayOption';
import { getVolcanoPlotThemeColors, getVolcanoWord } from '@components/plots/PlotUtil';
import RangeFieldGroup from '@components/experiments/plotDisplay/groups/RangeFieldGroup';
import PlotlyVolcanoPlotLegend from '@/src/components/analysisCategories/comparative/plots/PlotlyVolcanoPlotLegend';
import useDebouncedResizeObserver from '@/src/hooks/useDebouncedResizeObserver';
import { ResizableContainerContextProvider } from '@/src/contexts/ResizableContainerContext';
import LoadingMessage from '@/src/components/LoadingMessage';
import { useSwitchStyles } from '@/src/components/SwitchStyles';
import { Switch } from '@mui/material';

type Props = { plot: Plot; experiment: Experiment };
const VolcanoDisplayFields = ({ plot, experiment }: Props) => {
    const switchStyles = useSwitchStyles();
    const { values, setFieldValue } = useFormikContext<DisplayFormValue<VolcanoPlotDisplayOption>>();
    const display_type = values.display_type;
    const plotContainerRef = useRef<HTMLDivElement>(null);
    const { size: plotContainerSize } = useDebouncedResizeObserver({ ref: plotContainerRef, useDebounce: true });
    const [showPlotlyLegend, setShowPlotlyLegend] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShowPlotlyLegend(true);
        }, 50);
    }, []);

    const items = useMemo<CustomLegendColorItem[]>(() => {
        const volcanoColors = getVolcanoPlotThemeColors(values.theme_color);
        const volcanoWord = getVolcanoWord(plot.analysis_type);
        const legendItems: CustomLegendColorItem[] = [
            {
                id: 'increased',
                label: values.custom_legend_json?.['increased'] ?? `Increased ${volcanoWord}`,
                themeColor: volcanoColors.positive.color,
                labelName: '',
            },
            {
                id: 'decreased',
                label: values.custom_legend_json?.['decreased'] ?? `Decreased ${volcanoWord}`,
                themeColor: volcanoColors.negative.color,
                labelName: '',
            },
            {
                id: 'increasedTarget',
                label: values.custom_legend_json?.['increasedTarget'] ?? `Increased labeled ${volcanoWord}`,
                themeColor: volcanoColors.positiveTarget.color,
                labelName: '',
            },
            {
                id: 'decreasedTarget',
                label: values.custom_legend_json?.['decreasedTarget'] ?? `Decreased labeled ${volcanoWord}`,
                themeColor: volcanoColors.negativeTarget.color,
                labelName: '',
            },
            {
                id: 'significantIncreased',
                label: values.custom_legend_json?.['significantIncreased'] ?? `Significantly increased ${volcanoWord}`,
                themeColor: volcanoColors.significantPositive.color,
                labelName: '',
            },
            {
                id: 'significantDecreased',
                label: values.custom_legend_json?.['significantDecreased'] ?? `Significantly decreased ${volcanoWord}`,
                themeColor: volcanoColors.significantNegative.color,
                labelName: '',
            },
            {
                id: 'insignificantIncreased',
                label: values.custom_legend_json?.['insignificantIncreased'] ?? `Increased ${volcanoWord}`,
                themeColor: volcanoColors.insignificantPositive.color,
                lineThemeColor: volcanoColors.significantPositive.color,
                labelName: '',
            },
            {
                id: 'insignificantDecreased',
                label: values.custom_legend_json?.['insignificantDecreased'] ?? `Decreased ${volcanoWord}`,
                themeColor: volcanoColors.insignificantNegative.color,
                lineThemeColor: volcanoColors.significantNegative.color,
                labelName: '',
            },
        ];

        if (values.show_pval_line) {
            legendItems.push({
                id: 'pvalThresholdLine',
                label: 'Adjusted p-value threshold line',
                themeColor: volcanoColors.horizontal.color,
                labelName: '',
            });
        }

        if (values.show_fold_change_lines) {
            legendItems.push({
                id: 'foldChangeThresholdLineLower',
                label: 'Lower fold change threshold line',
                themeColor: volcanoColors.vertical.color,
            });
            legendItems.push({
                id: 'foldChangeThresholdLineUpper',
                label: 'Upper fold change threshold line',
                themeColor: volcanoColors.vertical.color,
            });
        }

        return legendItems;
    }, [
        values.theme_color,
        plot.analysis_type,
        values.show_pval_line,
        values.show_fold_change_lines,
        values.custom_legend_json,
    ]);

    return (
        <>
            <FullWidthToggleField />
            <ChartTitleField placeholder={plot.analysis?.name} />
            <PValueField
                name="pval_fill_threshold"
                tooltipTitle="Set a threshold to determine which points appear filled on the volcano plot"
                showHint
                defaultValue={DefaultVolcanoPlotDisplayOption.pval_fill_threshold}
            />
            <PValueToggleField />
            <FoldChangeFieldGroup />
            <FoldChangeToggleField />
            <section className="mb-8">
                <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">Axis ranges</h4>

                <div className="space-y-4">
                    <RangeFieldGroup
                        startName="x_axis_start"
                        endName="x_axis_end"
                        startLabel={
                            <span>
                                <span className="italic">x</span>-axis start
                            </span>
                        }
                        endLabel={
                            <span>
                                <span className="italic">x</span>-axis end
                            </span>
                        }
                    />
                    <RangeFieldGroup
                        startName="y_axis_start"
                        endName="y_axis_end"
                        startLabel={
                            <span>
                                <span className="italic">y</span>-axis start
                            </span>
                        }
                        endLabel={
                            <span>
                                <span className="italic">y</span>-axis end
                            </span>
                        }
                    />
                </div>
            </section>

            <ThemeFieldGroup hideStyle />

            <section className="mb-8">
                <h4 className="mb-0 text-lg font-semibold tracking-tight text-dark">Legend</h4>
                <p className="mb-2 text-xs text-gray-500/90">Click any annotation on the chart below to customize</p>
                <div className={'relative flex h-full w-full justify-center'} ref={plotContainerRef}>
                    <ResizableContainerContextProvider containerRef={plotContainerRef} size={plotContainerSize}>
                        {showPlotlyLegend ? (
                            <PlotlyVolcanoPlotLegend items={items} display={plot.display} />
                        ) : (
                            <LoadingMessage immediate message="Loading legend..." />
                        )}
                    </ResizableContainerContextProvider>
                </div>
            </section>

            <section className="mb-8">
                <div className="form-field !flex flex-row items-center justify-between">
                    <h4 className="text-lg font-semibold tracking-tight text-dark">Show target labels</h4>
                    <Switch
                        sx={switchStyles}
                        checked={values.custom_options_json?.show_target_labels ?? true}
                        name="show_target_labels"
                        onChange={(e) => {
                            setFieldValue('custom_options_json.show_target_labels', e.target.checked);
                        }}
                    />
                </div>
            </section>

            <section>
                <h4 className="mb-2 text-lg font-semibold tracking-tight text-dark">Labels</h4>
                <AsyncTargetPicker
                    experiment={experiment}
                    name="selected_targets"
                    description={
                        display_type === 'volcano_plot_v2'
                            ? 'Paste in a comma-separated or new line-separated list (case sensitive) or lasso-select points on the plot'
                            : 'Paste in a comma-separated or new line-separated list (case sensitive)'
                    }
                />
            </section>
        </>
    );
};

export default VolcanoDisplayFields;
