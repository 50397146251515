import Plot from '@models/Plot';
import { AnalysisParameters } from '@models/AnalysisParameters';
import React from 'react';
import SeuratMarkerExpressionAnalysisMethodsSection from '../analyses/SeuratMarkerExpressionAnalysisMethodsSection';
import { isSeuratMarkerExpressionAnalysisParameters } from '@/src/models/AnalysisParametersHelpers';
import AnnotationSet from '@/src/models/Annotation';

type Props = { plot: Plot; analysisParameters: AnalysisParameters | null; annotationSets: AnnotationSet[] | null };
const RidgePlotMethods = ({ plot, analysisParameters, annotationSets }: Props) => {
    if (
        plot.analysis_type === 'seurat_marker_expression' &&
        isSeuratMarkerExpressionAnalysisParameters(analysisParameters)
    ) {
        return (
            <SeuratMarkerExpressionAnalysisMethodsSection
                plot={plot}
                analysisParameters={analysisParameters}
                annotationSets={annotationSets}
            />
        );
    }
    return <p>Unsupported analysis type for violin plot, we&apos;re unable to find any methods.</p>;
};

export default RidgePlotMethods;
