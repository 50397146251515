import { ChangeEvent, useEffect, useState } from 'react';
import { TextareaAutosize } from '@mui/material';
import Button from '@components/Button';
import { Alert } from '@mui/material';
import Plot from '@models/Plot';
import { Comment } from '@models/Comment';
import useComments, { CommentValue, NewCommentValue } from '@hooks/useComments';
import useAuth from '@hooks/useAuth';
import CommentItem from '@components/experiments/comments/CommentItem';
import { ScrollableSidebarContent } from '@components/experiments/ScrollableSidebarContent';
import ButtonGroup, { ButtonGroupItem } from '@components/ButtonGroup';
import cn from 'classnames';
import { LoadingIndicator } from '@components/LoadingButton';
import AnnotationSet from '@/src/models/Annotation';
import { Biomarker } from '@/src/models/Biomarker';
import { LiteratureSet, Literature } from '@/src/models/LiteratureDatasets';

type Props = {
    plot: Plot | AnnotationSet | Biomarker | LiteratureSet | Literature;
    objectType?: 'plot' | 'clusterannotationset' | 'biomarker' | 'literatureset' | 'literature';
};

// TODO - Change plot to an abstract content type
const PlotCommentsView = ({ plot, objectType }: Props) => {
    const { user } = useAuth();
    const [selectedTab, setSelectedTab] = useState<CommentValue>('comment,note');
    const [newComment, setNewComment] = useState<string>('');
    const [newCommentType, setNewCommentType] = useState<NewCommentValue>('comment');
    const {
        comments,
        postNewComment,
        deleteComment,
        updateComment,
        updateCommentType,
        commentError,
        replyToComment,
        commentsLoading,
    } = useComments({
        object_type: objectType ?? 'plot',
        object_uuid: plot.uuid,
    });

    useEffect(() => {
        updateCommentType(selectedTab);
        if (selectedTab === 'comment,note') return;
        if (selectedTab) setNewCommentType(selectedTab);
    }, [selectedTab]);

    const handleChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setNewComment(e.target.value);
    };

    const handleKeyDown = (event) => {
        if (!event.shiftKey && event.key === 'Enter') {
            event.preventDefault();
            return handleCreateNewComment();
        }
    };

    const handleCreateNewComment = () => {
        postNewComment({ content: newComment, comment_type: newCommentType });
        setNewComment('');
    };

    const handleDeleteComment = (comment_uuid: string) => deleteComment({ comment_uuid });

    type UpdateCommentProps = { comment_uuid: string; content: string; comment_type: NewCommentValue };
    type ReplyCommentProps = { parent_comment: Comment; content: string };
    const handleUpdateComment = ({ comment_uuid, content, comment_type }: UpdateCommentProps) =>
        updateComment({ comment_uuid, content, comment_type });

    const handleReplyToComment = ({ parent_comment, content }: ReplyCommentProps) =>
        replyToComment({ parent_comment, content });

    const newCommentTabs: ButtonGroupItem<NewCommentValue>[] = [
        { value: 'comment', label: 'Comment' },
        { value: 'note', label: 'Note' },
    ];

    const tabs: ButtonGroupItem<CommentValue>[] = [
        { value: 'comment,note', label: 'All' },
        { value: 'comment', label: 'Comments' },
        { value: 'note', label: 'Notes' },
    ];

    const CommentFilterOption = ({ tab }: { tab: ButtonGroupItem<NewCommentValue> }) => (
        <div
            className={cn('', { 'cursor-pointer': newCommentType !== tab.value })}
            onClick={() => setNewCommentType(tab.value)}
        >
            <p
                className={cn('mb-1 font-semibold tracking-tight', {
                    'text-primary': newCommentType !== tab.value,
                    'text-slate-400': newCommentType === tab.value,
                })}
            >
                {tab.label}
            </p>
        </div>
    );

    const commentTypeText = selectedTab === 'note' ? 'notes' : 'comment';
    if (!plot || !user) return <></>;
    return (
        <>
            {commentError ? (
                <div className="m-2 mb-0 rounded">
                    <Alert severity="error">{commentError}</Alert>
                </div>
            ) : null}
            {commentsLoading ? (
                <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white opacity-50">
                    <LoadingIndicator size={48} />
                </div>
            ) : null}
            <div className="my-2 flex flex-col p-4 pb-2 pl-8">
                <div className="mb-2 flex overflow-x-auto px-8 pb-2">
                    <ButtonGroup
                        items={tabs}
                        onChange={(screen) => setSelectedTab(screen ?? 'comment,note')}
                        toggle={false}
                        value={selectedTab}
                    />
                </div>
                <div className="flex flex-col">
                    {selectedTab === 'comment,note' ? (
                        <div className="flex space-x-2">
                            {newCommentTabs.map((tab: ButtonGroupItem<NewCommentValue>, index: number) => (
                                <CommentFilterOption tab={tab} key={index} />
                            ))}
                        </div>
                    ) : null}
                    <TextareaAutosize
                        minRows={2}
                        maxRows={4}
                        className={cn('no_margin mb-2 rounded border-slate-300', {
                            'bg-indigo-50': newCommentType === 'note',
                        })}
                        onChange={handleChangeComment}
                        onKeyDown={handleKeyDown}
                        value={newComment}
                        placeholder={`Add a ${newCommentType === 'comment' ? 'comment' : 'note'}...`}
                    />
                    <div className="flex justify-between">
                        <div className="text-[11px] text-gray-400 pt-1">markdown supported</div>
                        <div>
                            <Button
                                className="self-end !py-[2px]"
                                variant="contained"
                                color="primary"
                                onClick={handleCreateNewComment}
                                disabled={!newComment}
                                size="small"
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {comments && comments.length ? (
                <ScrollableSidebarContent className="mb-4 pl-4 pr-4">
                    <div className="flex flex-col">
                        {comments?.map((comment: Comment) => (
                            <CommentItem
                                key={comment.uuid}
                                comment={comment}
                                handleDelete={handleDeleteComment}
                                handleReply={handleReplyToComment}
                                handleUpdate={handleUpdateComment}
                                userId={user.uuid ?? ''}
                                commentObjectType={objectType ?? 'plot'}
                            />
                        ))}
                    </div>
                </ScrollableSidebarContent>
            ) : (
                <p className="ml-8 text-default">
                    No {commentTypeText} yet. Be the first to share your thoughts and observations.
                </p>
            )}
        </>
    );
};

export default PlotCommentsView;
