import {
    AnyPlotDisplayOption,
    BarPlotDisplayOptionFormValues,
    BoxPlotDisplayOptionFormValues,
    CellScatterPlotDisplayOptionFormValues,
    DisplayFormValue,
    DotPlotDisplayOptionFormValues,
    EnrichmentPlotDisplayOptionFormValues,
    IGVPlotDisplayOptionFormValues,
    KaplanMeierCurveDisplayOptionFormValues,
    NetworkGraphDisplayOptionFormValues,
    PieChartDisplayOptionFormValues,
    PlotDisplayOptionFormValues,
    PlotLegendDisplayFormValues,
    ProfilePlotDisplayOptionFormValues,
    RidgePlotDisplayOptionFormValues,
    ScoresBarPlotPlotDisplayOptionFormValues,
    StackedBarPlotDisplayOptionFormValues,
    TornadoPlotDisplayOptionFormValues,
    VennDiagramDisplayOptionFormValues,
    ViolinPlotDisplayOptionFormValues,
} from '@models/PlotDisplayOption';
import { ComparativeHeatmapColorScale, ThemeColor, ThemeStyle } from '@models/PlotConfigs';
import { BaseExperimentAnalysis, ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import * as Yup from 'yup';
import HeatmapDisplayOption from '@models/plotDisplayOption/HeatmapDisplayOption';
import VolcanoPlotDisplayOption, {
    DefaultVolcanoPlotDisplayOption,
} from '@models/plotDisplayOption/VolcanoPlotDisplayOption';
import { PlotDisplayShortname } from '@models/PlotDisplayType';
import ImageDisplayOption from '@models/plotDisplayOption/ImageDisplayOption';
import SpreadsheetDisplayOption from '@models/plotDisplayOption/SpreadsheetDisplayOption';
import PrismGraphsetDisplayOption from '@models/plotDisplayOption/PrismDisplayOption';
import SampleScatterPlotDisplayOption from '@models/plotDisplayOption/SampleScatterPlotDisplayOption';
import EnrichmentPlotDisplayOption from '@models/plotDisplayOption/EnrichmentPlotDisplayOption';
import { AssaySummaryAnalysis } from '@models/analysis/AssaySummaryAnalysis';
import { GeneSetEnrichmentAnalysis } from '@models/analysis/GeneSetEnrichmentAnalysis';
import IGVPlotDisplayOption from '@models/plotDisplayOption/IGVPlotDisplayOption';
import KaplanMeierCurveDisplayOption from '@models/plotDisplayOption/KaplanMeierCurveDisplayOption';
import { SurvivalAnalysis } from '@models/analysis/SurvivalAnalysis';
import ScoreBarPlotDisplayOption from '@models/plotDisplayOption/ScoreBarPlotDisplayOption';
import { isDefined } from '@util/TypeGuards';
import LinePlotDisplayOption from '@models/plotDisplayOption/LinePlotDisplayOption';
import TextDisplayOption from '@models/plotDisplayOption/TextDisplayOption';
import ImageHeatmapDisplayOption from '@models/plotDisplayOption/ImageHeatmapDisplayOption';
import TornadoPlotDisplayOption from '@models/plotDisplayOption/TornadoPlotDisplayOption';
import ProfilePlotDisplayOption from '@models/plotDisplayOption/ProfilePlotDisplayOption';
import ExternalDisplayOption from '@models/plotDisplayOption/ExternalPlotDisplayOption';
import DotPlotDisplayOption from '@/src/models/plotDisplayOption/DotPlotDisplayOption';
import PieChartDisplayOption from '@/src/models/plotDisplayOption/PieChartDisplayOption';
import StackedBarPlotDisplayOption from '@/src/models/plotDisplayOption/StackedBarPlotDisplayOption';
import { PlotDataHeaderAnalysis } from '@/src/models/analysis/PlotDataHeaderAnalysis';
import NetworkGraphDisplayOption from '@/src/models/plotDisplayOption/NetworkGraphDisplayOption';
import { NetworkEdgeOptions } from './forms/NetworkGraphDisplayFields';
import VennDiagramDisplayOption from '@/src/models/plotDisplayOption/VennDiagramDisplayOption';
import { OverlapLists } from '@/src/models/analysis/OverlapAnalysis';
import RidgePlotDisplayOption from '@/src/models/plotDisplayOption/RidgePlotDisplayOption';
import ViolinPlotDisplayOption from '@/src/models/plotDisplayOption/ViolinPlotDisplayOption';
import CellScatterPlotDisplayOption from '@/src/models/plotDisplayOption/CellScatterPlotDisplayOption';

type AnyObjectSchema = Yup.AnyObjectSchema;
type SetupProps<D = AnyPlotDisplayOption, A = ExperimentAnalysis> = {
    display?: D | null;
    analysis?: A | null;
    displayType: PlotDisplayShortname | null;
};
const getVolcanoPlotInitialValues = ({
    display,
    displayType,
}: SetupProps): DisplayFormValue<VolcanoPlotDisplayOption> => {
    return {
        display_type: displayType ?? display?.display_type ?? 'volcano_plot',
        plot_title: display?.plot_title ?? DefaultVolcanoPlotDisplayOption.plot_title,
        theme_color: display?.theme_color ?? DefaultVolcanoPlotDisplayOption.theme_color,
        theme_style: display?.theme_style ?? DefaultVolcanoPlotDisplayOption.theme_style,
        show_pval_line: display?.show_pval_line ?? DefaultVolcanoPlotDisplayOption.show_pval_line,
        is_full_width: display?.is_full_width ?? false,
        pval_fill_threshold: display?.pval_fill_threshold ?? DefaultVolcanoPlotDisplayOption.pval_fill_threshold,
        show_fold_change_lines:
            display?.show_fold_change_lines ?? DefaultVolcanoPlotDisplayOption.show_fold_change_lines,
        fold_change_fill_threshold_lower:
            display?.fold_change_fill_threshold_lower ??
            DefaultVolcanoPlotDisplayOption.fold_change_fill_threshold_lower,
        fold_change_fill_threshold_upper:
            display?.fold_change_fill_threshold_upper ??
            DefaultVolcanoPlotDisplayOption.fold_change_fill_threshold_upper,
        selected_targets: display?.selected_targets ?? [],
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        custom_options_json: display?.custom_options_json ?? { show_target_labels: true },
        x_axis_start: display?.x_axis_start ?? DefaultVolcanoPlotDisplayOption.x_axis_start,
        x_axis_end: display?.x_axis_end ?? DefaultVolcanoPlotDisplayOption.x_axis_end,
        y_axis_start: display?.y_axis_start ?? DefaultVolcanoPlotDisplayOption.y_axis_start,
        y_axis_end: display?.y_axis_end ?? DefaultVolcanoPlotDisplayOption.y_axis_end,
    };
};
const getHeatmapInitialValues = ({
    display,
    analysis,
    ...props
}: SetupProps): DisplayFormValue<HeatmapDisplayOption> => {
    return {
        display_type: 'heatmap',
        plot_title: display?.plot_title ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
        theme_style: display?.theme_style ?? ThemeStyle.medium,
        is_full_width: display?.is_full_width ?? false,
        summarize_values_by: display?.summarize_values_by ?? 'mean',
        heatmap_scale_color: display?.heatmap_scale_color ?? ComparativeHeatmapColorScale.BLUE_WHITE_RED,
        adj_p_value_threshold: display?.adj_p_value_threshold ?? 0.01,
        is_transposed: display?.is_transposed ?? false,
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        ...(analysis?.category.shortname === 'comparative'
            ? undefined
            : getAssaySummaryLegendValues({
                  display,
                  analysis,
                  ...props,
              })),
    };
};

const getLinePlotInitialValues = (props: SetupProps): DisplayFormValue<LinePlotDisplayOption> => {
    const display = props.display;
    return {
        display_type: 'line_plot',
        plot_title: display?.plot_title ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
        theme_style: ThemeStyle.dark,
        is_full_width: display?.is_full_width ?? false,
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        y_axis_end: display?.y_axis_end ?? null,
        y_axis_start: display?.y_axis_start ?? null,
        y_axis_title: display?.y_axis_title ?? null,
        y_axis_scale: display?.y_axis_scale ?? 'linear',
        x_axis_start: display?.x_axis_start ?? null,
        x_axis_end: display?.x_axis_end ?? null,
        x_axis_title: display?.x_axis_title ?? null,
        error_bars_locations: display?.error_bars_locations ?? [],
        show_data_points: display?.show_data_points ?? false,
        error_bars_option: display?.error_bars_option ?? 'sd',
        show_error_bars: display?.show_error_bars ?? false,
        styles_json: display?.styles_json ?? {},
        ...getAssaySummaryLegendValues({ display, ...props }),
    };
};

const getImageInitialValues = (props: SetupProps): DisplayFormValue<ImageDisplayOption> => {
    const display = props.display as ImageDisplayOption;
    return {
        display_type: 'image',
        caption: display?.caption ?? '',
        image_source: display?.image_source ?? '',
        image_source_url: display?.image_source_url ?? '',
        plot_title: display?.plot_title ?? '',
        is_full_width: display?.is_full_width ?? false,
    };
};

const getSpreadsheetInitialValues = (props: SetupProps): DisplayFormValue<SpreadsheetDisplayOption> => {
    const display = props.display as SpreadsheetDisplayOption;
    return {
        display_type: 'spreadsheet',
        caption: display?.caption ?? '',
        spreadsheet_source: display?.spreadsheet_source ?? '',
        spreadsheet_source_url: display?.spreadsheet_source_url ?? '',
        plot_title: display?.plot_title ?? '',
        is_full_width: display?.is_full_width ?? false,
    };
};

const getPrismInitialValues = (props: SetupProps): DisplayFormValue<PrismGraphsetDisplayOption> => {
    const display = props.display as PrismGraphsetDisplayOption;
    return {
        display_type: 'prism_graphset',
        caption: display?.caption ?? '',
        selected_graph: display?.selected_graph ?? '',
        plot_title: display?.plot_title ?? '',
        is_full_width: display?.is_full_width ?? false,
    };
};

const getExternalPlotInitialValues = (props: SetupProps): DisplayFormValue<ExternalDisplayOption> => {
    const display = props.display as ExternalDisplayOption;
    return {
        display_type: 'external',
        plot_title: display?.plot_title ?? '',
        is_full_width: display?.is_full_width ?? false,
    };
};

const getImageHeatmapInitialValues = (props: SetupProps): DisplayFormValue<ImageHeatmapDisplayOption> => {
    const display = props.display as ImageHeatmapDisplayOption | undefined | null;
    return {
        display_type: 'image_heatmap',
        plot_title: display?.plot_title ?? '',
        cluster_samples: display?.cluster_samples ?? true,
        cluster_targets: display?.cluster_targets ?? true,
        is_full_width: display?.is_full_width ?? false,
        color_by_variables: display?.color_by_variables ?? [],
        heatmap_scale_color: display?.heatmap_scale_color ?? ComparativeHeatmapColorScale.BLUE_WHITE_RED,
    };
};

const getTextInitialValues = (props: SetupProps): DisplayFormValue<TextDisplayOption> => {
    const display = props.display as TextDisplayOption | null | undefined;
    return {
        display_type: 'text',
        plot_title: display?.plot_title ?? '',
        is_full_width: display?.is_full_width ?? false,
        attribution_url: display?.attribution_url ?? '',
        attribution_label: display?.attribution_label ?? '',
    };
};

const getSampleScatterPlotInitialValues = (setup: SetupProps): DisplayFormValue<SampleScatterPlotDisplayOption> => {
    const display = setup.display;
    return {
        display_type: 'sample_scatter_plot',
        plot_title: display?.plot_title ?? '',
        group_display_order: display?.group_display_order ?? [],
        x_axis_column: display?.x_axis_column ?? '',
        y_axis_column: display?.y_axis_column ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
        theme_style: display?.theme_style ?? ThemeStyle.medium,
        color_points_by: display?.color_points_by ?? [],
        is_full_width: display?.is_full_width ?? false,
        x_axis_start: display?.x_axis_start ?? null,
        x_axis_end: display?.x_axis_end ?? null,
        y_axis_start: display?.y_axis_start ?? null,
        y_axis_end: display?.y_axis_end ?? null,
        show_proportion_of_variance: display?.show_proportion_of_variance ?? false,
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
    };
};

const getAssaySummaryLegendValues = ({ analysis }: SetupProps): PlotLegendDisplayFormValues => {
    const summaryAnalysis = analysis as AssaySummaryAnalysis;
    return {
        legend: {
            group_display_order: summaryAnalysis?.group_display_order ?? summaryAnalysis.groups?.map((g) => g.id) ?? [],
        },
    };
};

const getGeneEnhancementInitialValues = (setup: SetupProps): EnrichmentPlotDisplayOptionFormValues => {
    const display = setup.display as EnrichmentPlotDisplayOption;
    const analysis = setup.analysis as GeneSetEnrichmentAnalysis;
    return {
        display_type: 'enrichment_plot',
        plot_title: display?.plot_title ?? '',
        plot_subtitle: display?.plot_subtitle ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
        theme_style: display?.theme_style ?? ThemeStyle.medium,
        analysis_values: { gene_set: analysis?.gene_set?.shortname ?? null },
        is_full_width: display?.is_full_width ?? false,
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        show_p_value: display?.show_p_value ?? false,
        show_nes_value: display?.show_nes_value ?? false,
    };
};

const getDotPlotInitialValues = (props: SetupProps): DotPlotDisplayOptionFormValues => {
    const display = props.display as DotPlotDisplayOption | undefined | null;
    return {
        display_type: 'dot_plot',
        is_full_width: display?.is_full_width ?? false,
        max_color: display?.max_color ?? '',
        min_color: display?.min_color ?? '',
        plot_title: display?.plot_title ?? '',
        plot_subtitle: display?.plot_subtitle ?? '',
        is_transposed: display?.is_transposed ?? false,
        max_gene_sets: display?.max_gene_sets ?? 50,
        max_gene_set_group_option: display?.max_gene_set_group_option ?? 'any',
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
    };
};

const getScoresBarPlotInitialValues = (setup: SetupProps): ScoresBarPlotPlotDisplayOptionFormValues => {
    const display = setup.display as ScoreBarPlotDisplayOption;
    // const analysis = setup.analysis as GeneSetEnrichmentAnalysis;
    return {
        display_type: 'score_bar_plot',
        plot_title: display?.plot_title ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
        theme_style: display?.theme_style ?? ThemeStyle.medium,
        adj_p_value_filter: display?.adj_p_value_filter ?? 0.001,
        top_scores_filter: display?.top_scores_filter ?? 10,
        nes_filter: display?.nes_filter ?? 'both',
        is_full_width: display?.is_full_width ?? false,
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
    };
};

const getIGVPlotInitialValues = (setup: SetupProps): IGVPlotDisplayOptionFormValues => {
    const display = setup.display as IGVPlotDisplayOption;
    return {
        display_type: 'igv_plot',
        plot_title: display?.plot_title ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
        theme_style: ThemeStyle.dark,
        color_tracks_by_variable_ids: display?.color_tracks_by_variable_ids ?? [],
        is_full_width: display?.is_full_width ?? true,
        group_display_order: (display?.group_display_order ?? []).filter(isDefined),
        config_json: display.config_json ?? null,
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        autoscale_tracks_by_group: display?.autoscale_tracks_by_group ?? false,
    };
};

const getKaplanMeierCurveInitialValues = (setup: SetupProps): KaplanMeierCurveDisplayOptionFormValues => {
    const display = setup.display as KaplanMeierCurveDisplayOption | null;
    const analysis = setup.analysis as SurvivalAnalysis;

    let group_display_order = display?.group_display_order ?? [];
    if (
        group_display_order.length !== analysis.groups.length ||
        !analysis.groups.every((g) => group_display_order.includes(g.id))
    ) {
        group_display_order = analysis.groups.map((g) => g.id);
    }

    return {
        display_type: 'kaplan_meier_curve',
        plot_title: display?.plot_title ?? '',
        y_axis_title: display?.y_axis_title ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
        theme_style: display?.theme_style ?? ThemeStyle.medium,
        is_full_width: display?.is_full_width ?? true,
        x_axis_title: display?.x_axis_title ?? '',
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        group_display_order,
    };
};

const getBarPlotInitialValues = ({ display, ...props }: SetupProps): BarPlotDisplayOptionFormValues => {
    return {
        display_type: 'bar_plot',
        plot_title: display?.plot_title ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
        theme_style: display?.theme_style ?? ThemeStyle.medium,
        color_points_by: [],
        error_bars_locations: display?.error_bars_locations ?? ['top', 'bottom'],
        shape_points_by: display?.shape_points_by ?? [],
        show_data_points: display?.show_data_points ?? true,
        show_error_bars: display?.show_error_bars ?? true,
        error_bars_option: display?.error_bars_option ?? 'sd',
        y_axis_end: display?.y_axis_end ?? null,
        y_axis_start: display?.y_axis_start ?? null,
        y_axis_title: display?.y_axis_title ?? null,
        y_axis_scale: display?.y_axis_scale ?? 'linear',
        is_full_width: display?.is_full_width ?? false,
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        ...getAssaySummaryLegendValues({ display, ...props }),
    };
};
const getBoxPlotInitialValues = ({ display, ...props }: SetupProps): BoxPlotDisplayOptionFormValues => {
    return {
        display_type: 'box_plot',
        plot_title: display?.plot_title ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
        theme_style: display?.theme_style ?? ThemeStyle.medium,
        color_points_by: [],
        shape_points_by: display?.shape_points_by ?? [],
        show_data_points: display?.show_data_points ?? true,
        y_axis_end: display?.y_axis_end ?? null,
        y_axis_start: display?.y_axis_start ?? null,
        y_axis_title: display?.y_axis_title ?? null,
        y_axis_scale: display?.y_axis_scale ?? 'linear',
        is_full_width: display?.is_full_width ?? false,
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        ...getAssaySummaryLegendValues({ display, ...props }),
    };
};
const getTornadoPlotInitialValues = (props: SetupProps): TornadoPlotDisplayOptionFormValues => {
    const display = props.display as TornadoPlotDisplayOption | undefined | null;
    return {
        display_type: 'tornado_plot',
        is_full_width: display?.is_full_width ?? false,
        max_color: display?.max_color ?? '',
        min_color: display?.min_color ?? '',
        plot_statistic: display?.plot_statistic ?? 'mean',
        plot_title: display?.plot_title ?? '',
        plots_to_show: display?.plots_to_show ?? 'phc',
        ref_point_label: display?.ref_point_label ?? 'TSS',
        z_max: display?.z_max ?? null,
        z_min: display?.z_min ?? null,
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
    };
};
const getProfilePlotInitialValues = (props: SetupProps): ProfilePlotDisplayOptionFormValues => {
    const display = props.display as ProfilePlotDisplayOption | undefined | null;
    return {
        display_type: 'profile_plot',
        is_full_width: display?.is_full_width ?? false,
        legend_location: display?.legend_location ?? 'best',
        plot_statistic: display?.plot_statistic ?? 'mean',
        plot_title: display?.plot_title ?? '',
        plot_type: display?.plot_type ?? 'lines',
        ref_point_label: display?.ref_point_label ?? 'TSS',
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
    };
};

const getStackedBarPlotInitialValues = (props: SetupProps): StackedBarPlotDisplayOptionFormValues => {
    const display = props.display as StackedBarPlotDisplayOption | undefined | null;
    const analysis = props.analysis as PlotDataHeaderAnalysis;

    return {
        display_type: 'stacked_bar_plot',
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        is_full_width: display?.is_full_width ?? false,
        is_transposed: display?.is_transposed ?? false,
        legend_display_order: display?.legend_display_order ?? analysis?.plot_data_headers?.slice(1) ?? [],
        plot_title: display?.plot_title ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
    };
};

const getPieChartInitialValues = (props: SetupProps): PieChartDisplayOptionFormValues => {
    const display = props.display as PieChartDisplayOption | undefined | null;
    const analysis = props.analysis as PlotDataHeaderAnalysis;
    return {
        display_type: 'pie_chart',
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        is_full_width: display?.is_full_width ?? false,
        legend_display_order: display?.legend_display_order ?? analysis?.plot_data_headers?.slice(1) ?? [],
        peak_set: display?.peak_set ?? '',
        plot_title: display?.plot_title ?? '',
        show_percentages: display?.show_percentages ?? true,
        theme_color: display?.theme_color ?? ThemeColor.cool,
    };
};

const getNetworkGraphInitialValues = (props: SetupProps): NetworkGraphDisplayOptionFormValues => {
    const display = props.display as NetworkGraphDisplayOption | undefined | null;
    return {
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        custom_options_json: display?.custom_options_json ?? {},
        display_type: 'network_graph',
        edge_types: display?.edge_types ?? [],
        hide_labels: display?.hide_labels ?? false,
        is_full_width: display?.is_full_width ?? false,
        legend_display_order: display?.legend_display_order ?? NetworkEdgeOptions.map((o) => o.id) ?? [],
        max_nodes_to_show: display?.max_nodes_to_show ?? 10,
        plot_title: display?.plot_title ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
        use_weighted_edges: display?.use_weighted_edges ?? true,
    };
};

const getVennDiagramInitialValues = (props: SetupProps): VennDiagramDisplayOptionFormValues => {
    const display = props.display as VennDiagramDisplayOption | undefined | null;
    const analysis = props.analysis as BaseExperimentAnalysis;
    const legendDisplayOrder = () => {
        switch (analysis?.analysis_type) {
            case 'overlap':
                const overlapItems: string[] = [];

                OverlapLists.forEach((listKey) => {
                    const list = analysis[listKey];
                    if (list?.uuid) {
                        overlapItems.push(list.uuid);
                    }
                });
                return overlapItems;
            default:
                return [];
        }
    };
    return {
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        custom_options_json: display?.custom_options_json ?? {},
        display_type: 'venn_diagram',
        is_full_width: display?.is_full_width ?? false,
        legend_display_order: display?.legend_display_order ?? legendDisplayOrder(),
        plot_title: display?.plot_title ?? '',
        show_labels: display?.show_labels ?? false,
        show_sublabels: display?.show_sublabels ?? false,
        theme_color: display?.theme_color ?? ThemeColor.cool,
        use_names_as_label: display?.use_names_as_label ?? false,
    };
};

const getRidgePlotInitialValues = (props: SetupProps): RidgePlotDisplayOptionFormValues => {
    const display = props.display as RidgePlotDisplayOption | undefined | null;
    return {
        annotation_set_id: display?.annotation_set_id ?? null,
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        custom_options_json: display?.custom_options_json ?? {},
        display_type: 'ridge_plot',
        group_display_order: display?.group_display_order ?? [],
        groups: display?.groups ?? null,
        is_full_width: display?.is_full_width ?? false,
        latent_variable_id: display?.latent_variable_id || null,
        plot_title: display?.plot_title ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
        variable_ids: display?.variable_ids ?? [],
    };
};

const getViolinPlotInitialValues = (props: SetupProps): ViolinPlotDisplayOptionFormValues => {
    const display = props.display as ViolinPlotDisplayOption | undefined | null;
    return {
        annotation_set_id: display?.annotation_set_id ?? null,
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        custom_options_json: display?.custom_options_json ?? {},
        display_type: 'violin_plot',
        group_display_order: display?.group_display_order ?? [],
        groups: display?.groups ?? null,
        is_full_width: display?.is_full_width ?? false,
        latent_variable_id: display?.latent_variable_id || null,
        plot_title: display?.plot_title ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
        use_jitter: display?.use_jitter ?? false,
        variable_ids: display?.variable_ids ?? [],
    };
};

const getCellScatterPlotInitialValues = (props: SetupProps): CellScatterPlotDisplayOptionFormValues => {
    const display = props.display as CellScatterPlotDisplayOption | undefined | null;
    return {
        annotation_set_id: display?.annotation_set_id ?? null,
        cell_coordinate_type: display?.cell_coordinate_type ?? 'umap',
        custom_color_json: display?.custom_color_json ?? {},
        custom_legend_json: display?.custom_legend_json ?? {},
        custom_options_json: display?.custom_options_json ?? {},
        display_type: 'cell_scatter_plot',
        groups: display?.groups ?? null,
        is_full_width: display?.is_full_width ?? false,
        latent_variable_id: display?.latent_variable_id || null,
        max_color: display?.max_color ?? '#883ae1',
        min_color: display?.min_color ?? '#d1d5db',
        plot_title: display?.plot_title ?? '',
        theme_color: display?.theme_color ?? ThemeColor.cool,
        variable_ids: display?.variable_ids ?? [],
        downsampling_num: display?.downsampling_num ?? 100_000,
        downsampling_seed: display?.downsampling_seed ?? 42,
    };
};

export const getPlotDisplayFormSetup = (
    props: SetupProps,
): {
    initialValues: PlotDisplayOptionFormValues;
    schema?: AnyObjectSchema | undefined;
} | null => {
    if (!props.displayType) {
        return null;
    }
    let initialValues: PlotDisplayOptionFormValues;
    const analysis = props.analysis;
    // TODO: Define a display form related validation schema (not sure we need this)
    let schema: AnyObjectSchema | undefined = undefined;
    switch (props.displayType) {
        case 'volcano_plot':
        case 'volcano_plot_v2':
            initialValues = getVolcanoPlotInitialValues(props);
            schema = Yup.object({
                pval_fill_threshold: Yup.number().required().nullable().label('Adjusted p-value').moreThan(0),
            });
            break;
        case 'box_plot':
            initialValues = getBoxPlotInitialValues(props);
            break;
        case 'bar_plot':
            initialValues = getBarPlotInitialValues(props);
            break;
        case 'heatmap':
            initialValues = getHeatmapInitialValues(props);
            if (analysis?.category.shortname === 'comparative') {
                schema = Yup.object({
                    adj_p_value_threshold: Yup.number().required().nullable().label('Adjusted p-value').moreThan(0),
                });
            }
            break;
        case 'line_plot':
            initialValues = getLinePlotInitialValues(props);
            break;
        case 'image':
            initialValues = getImageInitialValues(props);
            break;
        case 'spreadsheet':
            initialValues = getSpreadsheetInitialValues(props);
            break;
        case 'prism_graphset':
            initialValues = getPrismInitialValues(props);
            break;
        case 'image_heatmap':
            initialValues = getImageHeatmapInitialValues(props);
            break;
        case 'sample_scatter_plot':
            initialValues = getSampleScatterPlotInitialValues(props);
            schema = Yup.object({
                x_axis_end: Yup.number()
                    .nullable()
                    .when('x_axis_start', {
                        is: (val) => isDefined(val),
                        then: (schema) => schema.moreThan(Yup.ref('x_axis_start'), 'must be greater than x-axis start'),
                    }),
                x_axis_start: Yup.number().nullable(),
                y_axis_end: Yup.number()
                    .nullable()
                    .when('y_axis_start', {
                        is: (val) => isDefined(val),
                        then: (schema) => schema.moreThan(Yup.ref('y_axis_start'), 'must be greater than y-axis start'),
                    }),
                y_axis_start: Yup.number().nullable(),
                x_axis_column: Yup.string()
                    .required()
                    .test({
                        name: 'different',
                        exclusive: false,
                        message: 'must be different than the y-axis column',
                        test: function (value) {
                            return value !== this.parent.y_axis_column;
                        },
                    }),
                color_points_by: Yup.array().required().min(1, 'Please select at least one variable'),
                y_axis_column: Yup.string()
                    .required()
                    .test({
                        name: 'different',
                        exclusive: false,
                        message: 'must be different than the x-axis column',
                        test: function (value) {
                            return value !== this.parent.x_axis_column;
                        },
                    }),
            });
            break;
        case 'enrichment_plot':
            initialValues = getGeneEnhancementInitialValues(props);
            break;
        case 'igv_plot':
            initialValues = getIGVPlotInitialValues(props);
            break;
        case 'kaplan_meier_curve':
            initialValues = getKaplanMeierCurveInitialValues(props);
            break;
        case 'score_bar_plot':
            initialValues = getScoresBarPlotInitialValues(props);
            break;
        case 'text':
            initialValues = getTextInitialValues(props);
            break;
        case 'tornado_plot':
            initialValues = getTornadoPlotInitialValues(props);
            break;
        case 'profile_plot':
            initialValues = getProfilePlotInitialValues(props);
            break;
        case 'external':
            initialValues = getExternalPlotInitialValues(props);
            break;
        case 'dot_plot':
            initialValues = getDotPlotInitialValues(props);
            break;
        case 'network_graph':
            initialValues = getNetworkGraphInitialValues(props);
            break;
        case 'stacked_bar_plot':
            initialValues = getStackedBarPlotInitialValues(props);
            break;
        case 'pie_chart':
            initialValues = getPieChartInitialValues(props);
            break;
        case 'venn_diagram':
            initialValues = getVennDiagramInitialValues(props);
            break;
        case 'ridge_plot':
            initialValues = getRidgePlotInitialValues(props);
            break;
        case 'violin_plot':
            initialValues = getViolinPlotInitialValues(props);
            break;
        case 'cell_scatter_plot':
            initialValues = getCellScatterPlotInitialValues(props);
            break;
        default:
            return null;
    }

    return { initialValues, schema };
};
