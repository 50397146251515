import { usePlotContext } from '@contexts/PlotContext';
import LoadingMessage from '@components/LoadingMessage';
import dynamic from 'next/dynamic';
import { PlotParams } from 'react-plotly.js';
import { ArrowPlotData, PlotMapping, RidgePlotItem } from '@models/ExperimentData';
import React, { useEffect, useMemo, useRef } from 'react';
import useOnScreen from '@hooks/useOnScreen';
import { useResizableContainerContext } from '@contexts/ResizableContainerContext';
import Mono from '@components/elements/Mono';
import { useFeatureToggleContext } from '@contexts/FeatureToggleContext';
import Logger from '@util/Logger';
import {
    buildPlotlyLayout,
    IndexedDataPoint,
    prepareData,
} from '@components/analysisCategories/summary/plots/PlotlyCellScatterPlotUtil';
import Button from '@components/Button';
import { TimeoutValue } from '@util/ObjectUtil';
import { ColorScale } from 'plotly.js';
import { DotPlotIcon } from '@components/icons/custom/DotPlotIcon';
import { CustomPlotStylingOptions } from '@components/analysisCategories/comparative/plots/PlotlyVolcanoPlotUtil';
import CellScatterPlotDisplayOption from '@/src/models/plotDisplayOption/CellScatterPlotDisplayOption';

const logger = Logger.make('PlotlyCellScatterPlot');
const Plotly = dynamic(() => import('react-plotly.js'), { ssr: false });

type Props = { customPlotStylingOptions: CustomPlotStylingOptions | null };

const PlotlyCellScatterPlot = ({ customPlotStylingOptions = null }: Props) => {
    const { size, containerRef: resizeRef } = useResizableContainerContext();
    const timeoutRef = useRef<TimeoutValue | null>(null);
    const featureToggles = useFeatureToggleContext();
    const { onScreen, forceShow } = useOnScreen({ ref: resizeRef, initialOnScreen: true });
    const { plotData: pd, plotDataLoading, plot, publicationMode, isDragging } = usePlotContext();

    const plotData = pd as ArrowPlotData<RidgePlotItem> | null | undefined;

    // Maintain plot visibility after dragging
    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            forceShow();
        }, 10);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [isDragging]);

    if (plotDataLoading) {
        return <LoadingMessage immediate />;
    }

    if (!plotData) {
        return (
            <div className="flex h-full items-center justify-center">
                <div>No plot data is available</div>
            </div>
        );
    }

    if (!plot || !plot.display) {
        return (
            <div className="flex h-full items-center justify-center">
                <div>Unable to load plot: no plot was found</div>
            </div>
        );
    }

    const display = plot.display as CellScatterPlotDisplayOption;
    const items = plotData.items as RidgePlotItem[];
    const dataMap = plotData.plot_mapping as PlotMapping;
    const { preparedItems: preparedItemsMemo } = useMemo(() => {
        const { preparedItems } = prepareData({ items, dataMap });
        return { preparedItems };
    }, [items, dataMap]);

    const { data } = useMemo<{
        data: PlotParams['data'] | null;
    }>(() => {
        try {
            const minColor = display?.min_color ?? '#d1d5db';
            const maxColor = display?.max_color ?? '#883ae1';
            const DOT_RADIUS = display?.custom_options_json?.dot_radius ?? 2;
            const hasGroups = display?.groups && Object.keys(display?.groups).length > 0;

            const getColorScale = () => {
                return [
                    [0, minColor],
                    [1, maxColor],
                ];
            };
            const getMarkerColor = (d: IndexedDataPoint) => {
                return d.value;
            };
            const shouldHideDot = (d: IndexedDataPoint) => {
                const groupIsHidden = !display?.groups?.[d.group_id];
                if (hasGroups && groupIsHidden) {
                    return true;
                }
                return false;
            };

            // Define traces using scattergl for WebGL support
            const data: PlotParams['data'] = [
                {
                    type: 'scattergl',
                    name: '',
                    x: preparedItemsMemo.map((d) => d.x),
                    y: preparedItemsMemo.map((d) => d.y),
                    marker: {
                        symbol: 'circle',
                        color: preparedItemsMemo.map((d) => getMarkerColor(d)),
                        colorscale: getColorScale() as ColorScale,
                        size: DOT_RADIUS,
                        opacity: preparedItemsMemo.map((d) => (shouldHideDot(d) ? 0 : 1)),
                        line: {
                            color: preparedItemsMemo.map((d) => getMarkerColor(d)),
                            width: 1,
                            colorscale: getColorScale() as ColorScale,
                        },
                    },
                    hoverinfo: 'none',
                    mode: 'markers',
                    ygap: 2,
                },
            ];

            return { data };
        } catch (error) {
            logger.error(error);
            return { data: null };
        }
    }, [display, publicationMode, plotData, preparedItemsMemo, dataMap]);

    if (!data) {
        logger.warn('no processed data found', data, plotData);
        return null;
    }

    const layout = useMemo(() => {
        return buildPlotlyLayout({
            size,
            publicationMode,
            stylingOptions: customPlotStylingOptions ?? undefined,
            dataMap: plotData.plot_mapping as PlotMapping,
        });
    }, [display, preparedItemsMemo, publicationMode, size, customPlotStylingOptions, plotData.plot_mapping]);

    return (
        <>
            <div className="relative flex h-full w-full items-center justify-center">
                {onScreen || isDragging ? (
                    <Plotly
                        data={data}
                        layout={layout}
                        useResizeHandler={false}
                        config={{
                            displayModeBar: false, // TODO: use export mode to conditionally render this
                            autosizable: false,
                        }}
                    />
                ) : (
                    <div className="h-full w-full p-4">
                        <div className="flex h-full w-full flex-col items-center justify-center space-y-4 rounded-lg border border-indigo-100">
                            <div className="flex items-center">
                                <div className="rounded-full bg-indigo-100 p-3 text-indigo-600">
                                    <DotPlotIcon width={32} height={32} />
                                </div>
                            </div>
                            <div>
                                <Button onClick={() => forceShow()} variant="outlined" size="small" color="primary">
                                    Reload plot
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {featureToggles.isEnabled('plot_size_debug') && (
                    <div className="pointer-events-none absolute z-50 flex h-full w-full flex-col items-start justify-start border-2 border-dashed border-emerald-300">
                        <div className="z-50 bg-emerald-300/50 p-2 text-xs">
                            <p>
                                <Mono>PlotlyCellScatterPlot.tsx</Mono>
                            </p>
                            <p>height: {size?.height}px</p>
                            <p>width: {size?.width}px</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default PlotlyCellScatterPlot;
